<template>
	 <!-- v-show="muneShow" -->
	<el-scrollbar wrap-class="scrollbar-wrapper">
		<el-menu :default-active="$route.path" :unique-opened='true' mode="vertical" background-color="#304156" text-color="#bfcbd9"
		 active-text-color="#409EFF">
			<!-- <el-menu-item index="-1">
				<div style="color:#fff;font-size:26px;margin:40px 0 ;text-align:center"></div>
			</el-menu-item> -->
			<sidebar-item :item="routerObject" :slectRouter="slectRouter" :base-path="routerObject.path" />
		</el-menu>
	</el-scrollbar>
</template>

<script>
	import {
		mapGetters
	} from 'vuex'
	import SidebarItem from './SidebarItem'


	export default {
		props: {
			mylyshopList: {
				type: Object
			},
			mySelectMenu: {
				type: String,
				default: ''
			}
		},
		components: {
			SidebarItem
		},
		data() {
			return {
				muneShow: true,
				routerObject: {},
				slectRouter: ''
			}
		},
		computed: {
			...mapGetters([
				'permission_routers',
				'sidebar'
			]),
			isCollapse() {
				return !this.sidebar.opened
			}
		},
		watch: {
			mylyshopList(value) {
				this.routerObject = value;
				if (value.children.length < 2) {
					this.muneShow = false;
				} else {
					this.muneShow = true
				}
				this.$emit('getIshowmenu',this.muneShow)
			},
			mySelectMenu(value) {
				this.slectRouter = value;
			}
		},
	}
</script>
