import request from '@/utils/request'
//门店便签列表
export const GetStoretagList = (data) => request('/pc/ShopTag/list', data);
//添加编辑标签
export const EditStoretagInfo = (data) => request('/pc/ShopTag/Edit', data);
//删除标签
export const DeleteStoretag = (data) => request('/pc/ShopTag/Del', data);
//门店管理列表
export const StoreManageList = (data) => request('/pc/shop/shopmanagerlist', data);
//获取门店标签
export const StoreTagList = (data) => request('/pc/shop/shoptaglist', data);
//编辑添加门店保存
export const shopedit = (data) => request('/pc/shop/shopedit', data);
//删除门店
export const shopdelete = (data) => request('/pc/shop/shopdelete', data);
//删除限制
export const shopdeletelimitdata = (data) => request('/pc/shop/shopdeletelimitdata', data);
//门店详情
export const shopinfo = (data) => request('/pc/shop/shopinfo', data);
//商品设置
export const productsetshopproductconfig = (data) => request('/pc/shop/productsetshopproductconfig', data);
//按商品管理门店-列表
export const productshoplist = (data) => request('/pc/shop/productshoplist', data);
//商品信息
export const productbaseinfo = (data) => request('/pc/product/productbaseinfo', data);
//单个修改配送方式
export const shopproductsendtypeedit = (data) => request('/pc/shop/shopproductsendtypeedit', data);
//批量设置配送方式
export const setshopproductsendtype = (data) => request('/pc/shop/setshopproductsendtype', data);
//获取规格列表
export const productspecinfo = (data) => request('/pc/product/productspecinfo', data);
//设置供货价
export const setshopproductspecsupplyprice = (data) => request('/pc/shop/setshopproductspecsupplyprice', data);
//管理门店商品列表
export const shopproductconfigmanager = (data) => request('/pc/shop/shopproductconfigmanager', data);

export const shopproductsendtypebatchedit = (data) => request('/pc/shop/shopproductsendtypebatchedit', data);
//获取客户标签
export const GetmemberTaglist = (data) => request('/pc/memberTag/index', data);
//保存客户标签
export const SaveMembertags = (data) => request('/pc/memberTag/save', data);
//删除客户标签
export const MemberTagdelete = (data) => request('/pc/memberTag/delete', data);
//积分明细总统计
export const ScoresTotal = (data) => request('/pc/point/pointstatistics', data);
//积分管理列表
export const Scoreconfiglist = (data) => request('/pc/pointgiving/pointgivingconfiglist', data);
//获取积分明细列表
export const ScoreDetaillist = (data) => request('/pc/point/pointrecordlist', data);

//列表
export const activityPointExchangeList = (data) => request('/pc/activityPointExchange/list', data);
/**
 * 成长值配置
 */
//保存 
export const TaskConfigEdit = (data) => request('/pc/TaskConfig/Edit', data);
//获取配置信息
export const TaskConfigList = (data) => request('/pc/TaskConfig/List', data);
//积分管理添加/编辑
export const EditScoreconfig = (data) => request('/pc/pointgiving/pointgivingconfigedit', data);
//积分获取详情
export const getScoreconfigInfo = (data) => request('/pc/pointgiving/pointgivingconfigInfo', data);
// 门店对账账单汇总列表
export const BillTotalist = (data) => request('/pc/orderSummary/list', data);
//门店对账获取门店列表
export const Getallstorelist = (data) => request('/pc/shop/shopdroplist', data);
//门店对账账单明细
export const GetBilldetailist = (data) => request('/pc/orderParticulars/list', data);
//删除积分赠送配置
export const DeleteConfiginfo = (data) => request('/pc/pointgiving/pointgivingconfigdelete', data);
//初始化客户查询筛选条件
export const MemberInit = (data) => request('/pc/member/init', data);
//客户查询列表
export const MemberList = (data) => request('/pc/member/list', data);
//根据Id获取标签Ids
export const MemberIdTaginfos = (data) => request('/pc/member/getMemberTagList', data);
//会员等级筛选列表
export const MemberUserlevels = (data) => request('/pc/memberVipRole/filterBoxList', data);
//获取所有标签名称
// TagType传0
export const MemberTaglists = (data) => request('/pc/memberTag/popupList', data);
//锁粉店员查询
export const SearchLockEmployee = (data) => request('/pc/member/findLockEmployee', data);
//保存会员信息
export const SaveEmployeenInfo = (data) => request('/pc/member/save', data);
//单个修改客户标签
export const SingelChangetags = (data) => request('/pc/member/changeTag', data);
//根据Id获取客户标签
export const MemberIDtags = (data) => request('/pc/member/getMemberTagList', data);
//批量修改客户标签
export const EditsomeTags = (data) => request('/pc/member/batchAddTag', data);
//初始化会员等级
export const ChangelevelInit = (data) => request('/pc/member/changeVipLevelInit', data);
//修改单个等级
export const Savesingelevel = (data) => request('/pc/member/changeVipLevel', data);
//批量修改等级
export const SavesomeViplevels = (data) => request('/pc/member/batchChangeVipLevel', data);
//单个修改积分
export const Changesingelpoint = (data) => request('/pc/member/changePoint', data);
//批量修改积分
export const Changesomepoints = (data) => request('/pc/member/batchChangePoint', data);
//单个修改归属门店
export const ChangesingelShop = (data) => request('/pc/member/changeBelongShop', data);
//批量修改归属门店
export const ChangesomeShops = (data) => request('/pc/member/batchChangeBelongShop', data);
//修改单个锁粉店员
export const ChangesingelLock = (data) => request('/pc/member/changeLockMallEmployee', data);
//获取推手级别列表
export const filterBoxList = (data) => request('/pc/distributorRole/filterBoxList', data);
//根据手机号查找推荐人
export const findRecommendMember = (data) => request('/admin/userinfo/findByPhoen', data);
//初始化授权推手信息
export const authDistributorInit = (data) => request('/pc/member/authDistributorInit', data);
//保存推手信息
export const SaveauthDistributor = (data) => request('/pc/member/authDistributor', data);
//客户查询详情
export const MemberdetailInfo = (data) => request('/pc/member/info', data);
//积分清零
export const EmptyPoint = (data) => request('/pc/member/emptyPoint', data);
//返现列表
export const cashbackOrderList = (data) => request('/pc/member/cashbackOrderList', data);
//根据Id获取推荐人信息
export const memRecommendInfobyId = (data) => request('/pc/member/memberRecommendInfo', data);
//商户所有权限
export const Mallalljurisdict = (data) => request('/admin/menu/authoritiesList', data);
//首页实时数据
export const Indexrealtinedata = (data) => request('/pc/dataStatis/realTimeOverview', data);
//首页商城事项
export const IndexShoptings = (data) => request('/pc/dataStatis/realTimeMallItems', data);
//折线图
export const Indextrendsdata = (data) => request('/pc/dataStatis/realTimeSellInfo', data);
//交易分析
export const dataTransSurvey = (data) => request('/pc/dataStatis/dataTransactionAnalyze/tradeOverview', data);
//交易分析趋势图
export const dataTransTrade = (data) => request('/pc/dataStatis/dataTransactionAnalyze/tradeTrend', data);
//地域分布
export const AreaDistributdata = (data) => request('/pc/dataStatis/dataTransactionAnalyze/areaDistribution', data);
//交易构成
export const Constitutedata = (data) => request('/pc/dataStatis/dataTransactionAnalyze/transactionConstitute', data);
//支付金额分布图
export const payMoneybarData = (data) => request('/pc/dataStatis/dataTransactionAnalyze/payMoneyDistribution', data);
//标签成交分析
export const tagAnalyzeData = (data) => request('/pc/dataStatis/dataTransactionAnalyze/tagTransactionAnalyze', data);
//消费能力分析
export const consumeAbilityData = (data) => request('/pc/dataStatis/Customer/consumeAbility', data);
//消费频次分析
export const consumeFrequencyData = (data) => request('/pc/dataStatis/Customer/consumeFrequencyAnalyze', data);
//回购周期分析
export const memberRepeatPaydata = (data) => request('/pc/dataStatis/Customer/memberRepeatPay', data);
//营销概览
export const ActivityoverView = (data) => request('/pc/dataStatisActivity/overView', data);
//直播间交易数据
export const LiveroomTradedata = (data) => request('/pc/liveRoom/tradeStatis', data);
//结算业绩详情
export const shoperformancedetail = (data) => request('/pc/shop-performance/order-index', data);
//退款单列表
export const shoprefundList = (data) => request('/pc/shop-performance/refund-index', data);
//商城全局初始化
export const pcBaseInfo = () => request('/pc/mall/mallbaseinfo', {});
//提成明细
export const commissionlist = (data) => request('/pc/employeePerformance/commissionRecord', data);
//绑定物流列表
export const bindAccountlist = (data) => request('/pc/wx-express-assistant-account/index', data);
//打印员列表
export const printerlist = (data) => request('/pc/wx-express-assistant-printer/index', data);
//删除打印员
export const deletePrinter = (data) => request('/pc/wx-express-assistant-printer/delete', data);
//获取物流公司
export const RefreshExpressinfo = (data) => request('/pc/wx-express-assistant-account/sync', data);
//锁粉推手初始化
export const changeFirstFromMemberInit = (data) => request('/pc/member/changeFirstFromMemberInit', data);
//修改锁粉推手
export const changeFirstFromMember = (data) => request('/admin/userinfo/updateInvitendId', data);
//门店停用
export const shopClose = (data) => request('/pc/shop/shopClose', data);
//停用限制接口
export const shopCloseLimitData = (data) => request('/pc/shop/shopCloseLimitData', data);
//渠道数据统计接口
export const flowOverview = (data) => request('/pc/dataStatisPromoteCode/flowOverview', data);
//推广明细数据
export const generalizeDatalist = (data) => request('/pc/dataStatisPromoteCode/list', data);
//渠道商品明细
export const PromoteCodeproductList = (data) => request('/pc/dataStatisPromoteCode/productList', data);
//我的需求
export const malldemandList = (data) => request('/pc/malldemand/pcMallDemandList', data);
//新增需求
export const SubmitMallDemand = (data) => request('/pc/malldemand/submitMallDemand', data);
//需求详情
export const mallDemandDetail = (data) => request('/pc/malldemand/mallDemandDetail', data);
//1.3.0版本接口
//供货平台初始化
export const supplierMallBalanceInit = (data) => request('/pc/supplierMallBalance/init', data);
//供货平台列表
export const supplierMallBalanceList = (data) => request('/pc/supplierMallBalance/list', data);
//供货商品列表
export const supplierProductList = (data) => request('/pc/supplierProduct/list', data);
//分销供货单列表
export const supplierOrderList = (data) => request('/pc/supplier-order/index', data);
//分销供货单加星
export const orderIndexStar = (data) => request('/pc/supplier-order/star', data);
//分销单发货
export const orderSend = (data) => request('/pc/supplier-order/send', data);
//分销单查看物流
export const orderlookExpress = (data) => request('/pc/supplier-order/look-express', data);
// 分销单订单备注
export const orderRemark = (data) => request('/pc/supplier-order/remark', data);
//获取进度信息
export const orderimportGetprogress = (data) => request('/pc/supplier-order/order-import-send-get-progress', data);
//分销供货单详情
export const orderlookDetail = (data) => request('/pc/supplier-order/look-detail', data);
//分销单修改收货地址
export const ordereditReceive = (data) => request('/pc/supplier-order/edit-receive', data);
//分销单主动退款初始化
export const ordererefundInit = (data) => request('/pc/supplier-order/refund-init', data);
//分销单主动退款保存
export const ordererefundsave = (data) => request('/pc/supplier-order/refund-save', data);
//素材列表
export const productMateriaList = (data) => request('/pc/productMaterial/list', data);
//素材删除
export const productMateriaDelete = (data) => request('/pc/productMaterial/delete', data);
//保存素材
export const productMateriaSave = (data) => request('/pc/productMaterial/save', data);
//获取素材详情
export const productMateriaInfo = (data) => request('/pc/productMaterial/info', data);
//商品素材初始化
export const productMateriaInit = (data) => request('/pc/productMaterial/init', data);
//社群专享列表
export const activityCommunityGroupList = (data) => request('/pc/activityCommunityGroup/list', data);
//社群专享选择商品
export const activityCommunityGroupProductlist = (data) => request('/pc/activityCommunityGroup/productList', data);
//删除社群活动
export const activityCommunityGroupDelete = (data) => request('/pc/activityCommunityGroup/delete', data);
//社群活动使终止
export const activityCommunityGroupOpen = (data) => request('/pc/activityCommunityGroup/open', data);
//获取社群活动详情
export const activityCommunityGroupInfo = (data) => request('/pc/activityCommunityGroup/info', data);
//保存社群活动
export const activityCommunityGroupSave = (data) => request('/pc/activityCommunityGroup/save', data);


//广告位位置管理
//广告位列表
export const getAdPositionList = (data) => request('/admin/banner/findAll', data);
//广告位删除
export const delAdPosition = (data) => request('/admin/banner/delBanner', data);
//广告位详情
export const findAdPosition = (data) => request('/admin/banner/findById', data);
//广告位添加
export const addAdPosition = (data) => request('/admin/banner/addBanner', data);
//广告位编辑
export const updateAdPosition = (data) => request('/admin/banner/updateBanner', data);

//首页导航位置管理
//列表
export const getnNavigationList = (data) => request('/admin/navigation/getList', data);
//删除
export const navigationDel = (data) => request('/admin/navigation/del', data);
//添加
export const navigationAdd = (data) => request('/admin/navigation/add', data);
//编辑
export const navigationEdit = (data) => request('/admin/navigation/edit', data);

//商户收货地址管理
//列表
export const getAddressList = (data) => request('/admin/address/findAll', data);
//删除
export const addressDel = (data) => request('/admin/address/delReturnAddress', data);
//添加
export const addressAdd = (data) => request('/admin/address/addReturnAddress', data);
//编辑
export const addressEdit = (data) => request('/admin/address/updateReturnAddress', data);
//详情
export const addressFind = (data) => request('/admin/address/findById', data);


/**GSA新增**/

//活跃值变更
export const updGrowRate = (data) => request('/admin/userinfo/updGrowRate', data);
//用户成长值记录列表
export const userGrowRateList = (data) => request('/admin/userinfo/selectUserId', data);