/*订单类数据配置*/
const orderType = {
    '0':'全部',
    '1':'商品订单',
    '2':'活动订单',
    '3':'直播间订单',
    '4':'总部平台代下单',
    '5':'秒杀订单',
    '6':'卡券订单',
    '7':'组合套餐订单',
    '8':'礼包订单',
    '9':'奖品订单',
    '10':'拼团订单',
    '11':'24H模式订单',
    '12':'换货订单',
    '20':'人工补单',
}

export default {
    orderType
};
