import axios from 'axios'
import {
    Message,
    MessageBox
} from 'element-ui'
import store from '@/store/index'
import config from '@/config/index'

// create an axios instance
const service = axios.create({
    withCredentials: true,
    baseURL: config.BASE_URL, // api的base_url
    // timeout: 10000 // request timeout
})

// request interceptor
service.interceptors.request.use(config => {
    // Do something before request is sent
    if (store.getters.token) {
        // config.headers['X-Token'] = getToken() // 让每个请求携带token-- ['X-Token']为自定义key 请根据实际情况自行修改
    }
    // console.log(config);
    return config
}, error => {
    // Do something with request error
    console.log(error) // for debug
    Promise.reject(error)
})

// respone interceptor
service.interceptors.response.use(response => {
    if (response.data.code == 401) {
        location.reload() // 为了重新实例化vue-router对象 避免bug
        return;
    }
    if (response.data.ErrorCode) {
        //限时折扣 如果有商品冲突 需要显示冲突商品,所以还是要把数据返回回去 然后在页面里判断
        if (response.data.Result && response.data.Result.length) {
            return response.data
        } else {
            Message({
                showClose: true,
                message: response.data.Message,
                type: 'error',
                duration: 5 * 1000
            })
            return Promise.reject(response.data.Message)
        }
    } else if (!response.data.code) {
        return response;
    } else {
        return response.data;
    }
}, error => {
    if (error.response.code == '401') {
        MessageBox.confirm('你已被登出，可以取消继续留在该页面，或者重新登录', '确定登出', {
            confirmButtonText: '重新登录',
            cancelButtonText: '取消',
            type: 'warning'
        }).then(() => {
            // 先注释着
            // router.push({
            // 	path: '/login'
            // })

            store.dispatch('FedLogOut').then(() => {
                location.reload() // 为了重新实例化vue-router对象 避免bug
            })
        })
    } else {
        console.log('error:' + error.message) // for debug
        Message({
            showClose: true,
            message: error.message,
            type: 'error',
            duration: 5 * 1000
        })
    }
    return Promise.reject(error)
})


export default async (url = '', data = {}, type = 'post') => {
    // console.log(url);
    for (let key in data) {
        if (typeof (data[key]) == 'string') {
            data[key] = data[key].replace(/(^\s*)|(\s*$)/g, "")
        } else if (typeof (data[key]) == 'object' && (!Array.isArray(data[key]))) {
            let chiildData = data[key]
            for (let keyWords in chiildData) {
                if (typeof (chiildData[keyWords]) == 'string') {
                    chiildData[keyWords] = chiildData[keyWords].replace(/(^\s*)|(\s*$)/g, "")
                }
            }
        }
    }
    if(data.URLLINK) {
        url += '?';
        for (let key in data) {
            url += (key + '=' + data[key] + '&');
        }
    }
    if (type === 'get') {
        return service({
            url: url,
            method: type,
            params: data,
        })
    } else if (type === 'post') {
        if (data.isDownload) {
            console.log("responseType: 'blob'");
            return service({
                url: url,
                method: type,
                data: data,
                responseType: 'blob' // 表明返回服务器返回的数据类型
            })
        } else {
            return service({
                url: url,
                method: type,
                data: data,
            })
        }

    }
}