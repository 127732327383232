import router from './router/index'
import store from './store/index'
import {
    Message
} from 'element-ui'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style

NProgress.configure({
    showSpinner: false
}) // NProgress Configuration

const whiteList = ['/login', '/authredirect'] // no redirect whitelist

router.beforeEach((to, from, next) => {
    NProgress.start() // start progress bar
    let islogin = window.localStorage.getItem('islogin');
    if (islogin == 'true') { // determine if there has token
        /* has token*/
        if (to.path === '/login') {
            next({
                path: '/'
            })
            NProgress.done() // if current page is dashboard will not trigger	afterEach hook, so manually handle it
        } else {
            if (!store.getters.isGet) { // 判断是否已经获取过路由信息
                store.dispatch('GenerateRoutes').then(() => { // 生成可访问的路由表
                    // console.log(store.getters.addRouters)         
                    router.addRoutes(store.getters.addRouters) // 动态添加可访问路由表
                    next({
                        ...to,
                        replace: true
                    }) // hack方法 确保addRoutes已完成 ,set the replace: true so the navigation will not leave a history record
                }).catch((err) => {
                    console.log(err)
                    store.dispatch('FedLogOut').then(() => {
                        Message.error('身份验证失败，请重新登录！')
                        next({
                            path: '/login'
                        })
                    })
                })
            } else {
                next()
            }
        }
    } else {
        /* has no token*/
        if (whiteList.indexOf(to.path) !== -1) { // 在免登录白名单，直接进入
            next()
        } else {
            next(`/login?redirect=${to.path}`) // 否则全部重定向到登录页
            NProgress.done() // if current page is login will not trigger afterEach hook, so manually handle it
        }
    }
})

router.afterEach(() => {
    NProgress.done() // finish progress bar
})