const imageUrl = 'https://cdn.dkycn.cn/images/dyj/';
const oldImageUrl = 'http://cdn.dkycn.cn/Images/zmkm/fit/';

export default {
    menu1           : imageUrl + 'menu-1.jpg',
    menu2           : imageUrl + 'menu-2.jpg',
    menu3           : imageUrl + 'menu-3.jpg',
    menu4           : imageUrl + 'menu-4.jpg',
    menu5           : imageUrl + 'menu-5.jpg',
    menu6           : imageUrl + 'menu-6.jpg',
    menu7           : imageUrl + 'menu-7.jpg',
    menu8           : imageUrl + 'menu-8.jpg',
    menu9           : imageUrl + 'menu-9.jpg',
    menu10          : imageUrl + 'menu-10.jpg',
    menu11          : imageUrl + 'menu-11.jpg',
    menu12          : imageUrl + 'menu-12.jpg',
    menu13          : imageUrl + 'menu-13.jpg',
    menu14          : imageUrl + 'menu-14.png',
    menu15          : imageUrl + 'menu-15.jpg',
    menu16          : imageUrl + 'menu-16.jpg',
    menu17          : imageUrl + 'menu-17.jpg',
    menu18          : imageUrl + 'menu-18.jpg',
	menu19          : imageUrl + 'menu-19.png',
	menu20          : imageUrl + 'menu-20.jpg',
	menu21          : imageUrl + 'menu-21.png',
	menu22          : imageUrl + 'menu-22.png',
	menu23          : imageUrl + 'menu-23.png',
	menu24          : imageUrl + 'menu-24.jpg',
    title           : imageUrl + 'module-title.jpg',
    name            : imageUrl + 'module-name.jpg',
    attention       : imageUrl + 'module-attention.jpg',
    noPic           : imageUrl + 'no-pic.png',
    noPic1          : imageUrl + 'no-pic1.jpg',
    noVideo         : imageUrl + 'no-video.png',
    iconPlus        : imageUrl + 'dyj-page-icon-plus.jpg',
    guessyourlike   : imageUrl + 'guessyourlike-name.jpg',
    deleteIcon      : imageUrl + 'delete-j.jpg',
    noSlider        : imageUrl + 'no-slider.jpg',
    eraserIcon      : imageUrl + 'eraser-icon.png',
    plusIcon        : imageUrl + 'plus-icon.png',
    flashSale       : imageUrl + 'dyj-flashsale-icon.jpg',
    phonePic        : imageUrl + 'dyj-phone-pic.jpg',


    /**
    ** 优惠券命名规则
    ** 第一个数字代表颜色类型 第二个数字代表样式类型 第三个数字代表金额-1:10 2:20
    **/
    coupon111       : imageUrl + 'coupon-1-1-1.jpg',
    coupon112       : imageUrl + 'coupon-1-1-2.jpg',
    coupon121       : imageUrl + 'coupon-1-2-1.jpg',
    coupon122       : imageUrl + 'coupon-1-2-2.jpg',
    coupon131       : imageUrl + 'coupon-1-3-1.jpg',
    coupon132       : imageUrl + 'coupon-1-3-1.jpg',
    
    coupon211       : imageUrl + 'coupon-2-1-1.jpg',
    coupon212       : imageUrl + 'coupon-2-1-2.jpg',
    coupon221       : imageUrl + 'coupon-2-2-1.jpg',
    coupon222       : imageUrl + 'coupon-2-2-2.jpg',
    coupon231       : imageUrl + 'coupon-2-3-1.jpg',
    coupon232       : imageUrl + 'coupon-2-3-2.jpg',

    coupon311       : imageUrl + 'coupon-3-1-1.jpg',
    coupon312       : imageUrl + 'coupon-3-1-2.jpg',
    coupon321       : imageUrl + 'coupon-3-2-1.jpg',
    coupon322       : imageUrl + 'coupon-3-2-2.jpg',
    coupon331       : imageUrl + 'coupon-3-3-1.jpg',
    coupon332       : imageUrl + 'coupon-3-3-2.jpg',
    
    couponIcon1     : imageUrl + 'coupon-icon-1.jpg',
    couponIcon2     : imageUrl + 'coupon-icon-2.jpg',
    couponIcon3     : imageUrl + 'coupon-icon-3.jpg',


    /*
    **底部工具栏图片
    **第一个数字分类 第二个数字索引
    **/
    'footer-1-1'     : oldImageUrl + 'tab-1-1.png',
    'footer-1-2'     : oldImageUrl + 'tab-1-2.png',
    'footer-1-3'     : oldImageUrl + 'tab-1-3.png',
    'footer-1-4'     : oldImageUrl + 'tab-1-4.png',
    'footer-1-5'     : oldImageUrl + 'tab-1-5.png',
    'footer-1-6'     : oldImageUrl + 'tab-1-6.png',
    'footer-1-7'     : oldImageUrl + 'tab-1-7.png',
    'footer-1-8'     : oldImageUrl + 'tab-1-8.png',
    'footer-1-9'     : oldImageUrl + 'tab-1-9.png',
    'footer-1-10'    : oldImageUrl + 'tab-1-10.png',
    'footer-1-11'    : oldImageUrl + 'tab-1-11.png',
    'footer-1-12'    : oldImageUrl + 'tab-1-12.png',
    'footer-1-13'    : oldImageUrl + 'tab-1-13.png',
    'footer-1-14'    : oldImageUrl + 'tab-1-14.png',
    'footer-1-15'    : oldImageUrl + 'tab-1-15.png',
    'footer-1-16'    : oldImageUrl + 'tab-1-16.png',
    'footer-1-17'    : oldImageUrl + 'tab-1-17.png',
    'footer-1-18'    : oldImageUrl + 'tab-1-18.png',
    'footer-1-19'    : oldImageUrl + 'tab-1-19.png',
    'footer-1-20'    : oldImageUrl + 'tab-1-20.png',

    'footer-2-1'     : oldImageUrl + 'tab-2-1.png',
    'footer-2-2'     : oldImageUrl + 'tab-2-2.png',
    'footer-2-3'     : oldImageUrl + 'tab-2-3.png',
    'footer-2-4'     : oldImageUrl + 'tab-2-4.png',
    'footer-2-5'     : oldImageUrl + 'tab-2-5.png',
    'footer-2-6'     : oldImageUrl + 'tab-2-6.png',
    'footer-2-7'     : oldImageUrl + 'tab-2-7.png',
    'footer-2-8'     : oldImageUrl + 'tab-2-8.png',
    'footer-2-9'     : oldImageUrl + 'tab-2-9.png',
    'footer-2-10'    : oldImageUrl + 'tab-2-10.png',
    'footer-2-11'    : oldImageUrl + 'tab-2-11.png',
    'footer-2-12'    : oldImageUrl + 'tab-2-12.png',
    'footer-2-13'    : oldImageUrl + 'tab-2-13.png',
    'footer-2-14'    : oldImageUrl + 'tab-2-14.png',
    'footer-2-15'    : oldImageUrl + 'tab-2-15.png',

    'footer-3-1'     : oldImageUrl + 'tab-3-1.png',
    'footer-3-2'     : oldImageUrl + 'tab-3-2.png',
    'footer-3-3'     : oldImageUrl + 'tab-3-3.png',
    'footer-3-4'     : oldImageUrl + 'tab-3-4.png',
    'footer-3-5'     : oldImageUrl + 'tab-3-5.png',
    'footer-3-6'     : oldImageUrl + 'tab-3-6.png',
    'footer-3-7'     : oldImageUrl + 'tab-3-7.png',
    'footer-3-8'     : oldImageUrl + 'tab-3-8.png',
    'footer-3-9'     : oldImageUrl + 'tab-3-9.png',
    'footer-3-10'    : oldImageUrl + 'tab-3-10.png',
    'footer-3-11'    : oldImageUrl + 'tab-3-11.png',
    'footer-3-12'    : oldImageUrl + 'tab-3-12.png',
    'footer-3-13'    : oldImageUrl + 'tab-3-13.png',
    'footer-3-14'    : oldImageUrl + 'tab-3-14.png',
    'footer-3-15'    : oldImageUrl + 'tab-3-15.png',

    'footer-4-1'     : oldImageUrl + 'tab-4-1.png',
    'footer-4-2'     : oldImageUrl + 'tab-4-2.png',
    'footer-4-3'     : oldImageUrl + 'tab-4-3.png',
    'footer-4-4'     : oldImageUrl + 'tab-4-4.png',
    'footer-4-5'     : oldImageUrl + 'tab-4-5.png',
    'footer-4-6'     : oldImageUrl + 'tab-4-6.png',
    'footer-4-7'     : oldImageUrl + 'tab-4-7.png',
    'footer-4-8'     : oldImageUrl + 'tab-4-8.png',
    'footer-4-9'     : oldImageUrl + 'tab-4-9.png',
    'footer-4-10'    : oldImageUrl + 'tab-4-10.png',
    'footer-4-11'    : oldImageUrl + 'tab-4-11.png',
    'footer-4-12'    : oldImageUrl + 'tab-4-12.png',
    'footer-4-13'    : oldImageUrl + 'tab-4-13.png',
    'footer-4-14'    : oldImageUrl + 'tab-4-14.png',
    'footer-4-15'    : oldImageUrl + 'tab-4-15.png',

    'footer-5-1'     : oldImageUrl + 'tab-5-1.png',
    'footer-5-2'     : oldImageUrl + 'tab-5-2.png',
    'footer-5-3'     : oldImageUrl + 'tab-5-3.png',
    'footer-5-4'     : oldImageUrl + 'tab-5-4.png',
    'footer-5-5'     : oldImageUrl + 'tab-5-5.png',
    'footer-5-6'     : oldImageUrl + 'tab-5-6.png',
    'footer-5-7'     : oldImageUrl + 'tab-5-7.png',
    'footer-5-8'     : oldImageUrl + 'tab-5-8.png',
    'footer-5-9'     : oldImageUrl + 'tab-5-9.png',
    'footer-5-10'    : oldImageUrl + 'tab-5-10.png',
    'footer-5-11'    : oldImageUrl + 'tab-5-11.png',
    'footer-5-12'    : oldImageUrl + 'tab-5-12.png',
    'footer-5-13'    : oldImageUrl + 'tab-5-13.png',
    'footer-5-14'    : oldImageUrl + 'tab-5-14.png',
    'footer-5-15'    : oldImageUrl + 'tab-5-15.png',

    'footer-6-1'     : oldImageUrl + 'tab-6-1.png',
    'footer-6-2'     : oldImageUrl + 'tab-6-2.png',
    'footer-6-3'     : oldImageUrl + 'tab-6-3.png',
    'footer-6-4'     : oldImageUrl + 'tab-6-4.png',
    'footer-6-5'     : oldImageUrl + 'tab-6-5.png',
    'footer-6-6'     : oldImageUrl + 'tab-6-6.png',
    'footer-6-7'     : oldImageUrl + 'tab-6-7.png',
    'footer-6-8'     : oldImageUrl + 'tab-6-8.png',
    'footer-6-9'     : oldImageUrl + 'tab-6-9.png',
    'footer-6-10'    : oldImageUrl + 'tab-6-10.png',
    'footer-6-11'    : oldImageUrl + 'tab-6-11.png',
    'footer-6-12'    : oldImageUrl + 'tab-6-12.png',

    'footer-7-1'     : oldImageUrl + 'tab-7-1.png',
    'footer-7-2'     : oldImageUrl + 'tab-7-2.png',
    'footer-7-3'     : oldImageUrl + 'tab-7-3.png',
    'footer-7-4'     : oldImageUrl + 'tab-7-4.png',
    'footer-7-5'     : oldImageUrl + 'tab-7-5.png',
    'footer-7-6'     : oldImageUrl + 'tab-7-6.png',
    'footer-7-7'     : oldImageUrl + 'tab-7-7.png',
    'footer-7-8'     : oldImageUrl + 'tab-7-8.png',
    'footer-7-9'     : oldImageUrl + 'tab-7-9.png',
    'footer-7-10'    : oldImageUrl + 'tab-7-10.png',
    'footer-7-11'    : oldImageUrl + 'tab-7-11.png',
    'footer-7-12'    : oldImageUrl + 'tab-7-12.png',
    'footer-7-13'    : oldImageUrl + 'tab-7-13.png',
    'footer-7-14'    : oldImageUrl + 'tab-7-14.png',
    'footer-7-15'    : oldImageUrl + 'tab-7-15.png',
    'footer-7-16'    : oldImageUrl + 'tab-7-16.png',
    'footer-7-17'    : oldImageUrl + 'tab-7-17.png',
    'footer-7-18'    : oldImageUrl + 'tab-7-18.png',
    'footer-7-19'    : oldImageUrl + 'tab-7-19.png',
    'footer-7-20'    : oldImageUrl + 'tab-7-20.png',
    'footer-7-21'    : oldImageUrl + 'tab-7-21.png',
    'footer-7-22'    : oldImageUrl + 'tab-7-22.png',
    'footer-7-23'    : oldImageUrl + 'tab-7-23.png',
    'footer-7-24'    : oldImageUrl + 'tab-7-24.png',
    'footer-7-25'    : oldImageUrl + 'tab-7-25.png',
    'footer-7-26'    : oldImageUrl + 'tab-7-26.png',
    'footer-7-27'    : oldImageUrl + 'tab-7-27.png',
    'footer-7-28'    : oldImageUrl + 'tab-7-28.png',
    'footer-7-29'    : oldImageUrl + 'tab-7-29.png',
    'footer-7-30'    : oldImageUrl + 'tab-7-30.png',
    'footer-7-31'    : oldImageUrl + 'tab-7-31.png',
    'footer-7-32'    : oldImageUrl + 'tab-7-32.png',
    'footer-7-33'    : oldImageUrl + 'tab-7-33.png',
    'footer-7-34'    : oldImageUrl + 'tab-7-34.png',
    'footer-7-35'    : oldImageUrl + 'tab-7-35.png',
    'footer-7-36'    : oldImageUrl + 'tab-7-36.png',
    'footer-7-37'    : oldImageUrl + 'tab-7-37.png',
    'footer-7-38'    : oldImageUrl + 'tab-7-38.png',
    'footer-7-39'    : oldImageUrl + 'tab-7-39.png',
    'footer-7-40'    : oldImageUrl + 'tab-7-40.png',

}