// 枚举
// 损益单状态枚举列表
export default {
    ProfitLossState: [
        { Id:1, Name:'待提交' },
        { Id:2, Name:'待审批' },
        { Id:3, Name:'审批通过' },
        { Id:4, Name:'审批否决' },
    ],
    Level_list: [
        { pusherLevel: 1, pusherLabel: '1级', discountRate: null},
        { pusherLevel: 2, pusherLabel: '2级', discountRate: null},
        { pusherLevel: 3, pusherLabel: '3级', discountRate: null},
        { pusherLevel: 4, pusherLabel: '4级', discountRate: null},
    ],
    prizeType:[
        { state:0, str:'未中奖'},
        { state:1, str:'商品'},
        { state:2, str:'优惠券'},
        { state:null, str:'全部'}
    ]
}