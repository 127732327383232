<template>
	<div class="tags-view-container">
		<div class="view-container-childs">
			<el-row>
				<el-col :span="3">
					<div
						style="background: #1a2533;height:60px;line-height:60px;text-align: left;padding-left: 40px;"
					>
						<img
							src=""
							alt
							style="width: 116px;margin-right:30px"
						/>
					</div>
				</el-col>
				<el-col :span="14">
					<div style="background: #1a2533;height:60px;line-height:60px;"></div>
				</el-col>
				<el-col :span="7" style="background:#1a2533;position: relative;">
					<div class="right-menu-people">
						<el-dropdown
							class="avatar-container right-menu-item"
							trigger="click"
							:title="'欢迎用户'+name"
							@command="handleCommand"
						>
							<div class="avatar-wrapper">
								<!-- <img v-if="localPageWxurl!=''" :src="localPageWxurl" class="user-avatar" />
                <img
                  v-else
                  src="https://api.static.huibogoulive.cn/images/CJYP/static/img/icons/avatardefault.png"
                  class="user-avatar"
								/>-->
								<div class="mall-message" style="margin-left:10px;color:#fff">
									<div style="line-height:20px;">{{localPageName}}</div>
									<div style="line-height:20px;">{{localpageRoles}}</div>
								</div>
								<i class="el-icon-arrow-right" style="color:#fff;vertical-align:10px;margin-left:10px;" />
							</div>
							<el-dropdown-menu slot="dropdown">
								<el-dropdown-item command="1">
									<!-- @click="changePasswaord" -->
									<span>修改密码</span>
								</el-dropdown-item>
								<el-dropdown-item command="2">
									<!-- @click="logout" -->
									<span style="display:block;">{{ $t('navbar.logOut') }}</span>
								</el-dropdown-item>
							</el-dropdown-menu>
						</el-dropdown>
					</div>
				</el-col>
			</el-row>
			<scroll-pane ref="scrollPane" class="tags-view-wrapper">
				<router-link
					v-for="(tag,indexr) in Array.from(visitedViews)"
					ref="tag"
					:class="isActive(tag)?'active':''"
					:to="tag"
					:key="indexr"
					class="tags-view-item"
					@contextmenu.prevent.native="openMenu(tag,$event)"
				>
					{{ generateTitle(tag.title) }}
					<span
						class="el-icon-close"
						@click.prevent.stop="closeSelectedTag(tag)"
					/>
				</router-link>
			</scroll-pane>
			<div class="mfl-right-buttons">
				<el-dropdown>
					<span class="el-dropdown-link">
						<el-button type="primary" size="mini" style="width:100px;height:30px;line-height: 10px;">标签选项</el-button>
					</span>
					<el-dropdown-menu slot="dropdown">
						<el-dropdown-item command="clearAll" @click.native="handleTagsClose">关闭所有</el-dropdown-item>
						<el-dropdown-item command="clearOthers" @click.native="handleCloseother">关闭其他</el-dropdown-item>
					</el-dropdown-menu>
				</el-dropdown>
			</div>
		</div>
		<el-dialog
			title="修改密码"
			:visible.sync="SendDialogVisible"
			width="850px"
			:loading="loading"
			class="editpassards"
			style="z-index: 2016!important;"
		>
			<el-form :model="sendForm" ref="sendForm" :rules="rules" style="width:47%;margin-left: 10%;">
				<el-form-item label="旧密码" label-width="150px" prop="oldPs">
					<el-input
						v-model="sendForm.oldPs"
						placeholder="请输入旧密码"
						:type="psoldType"
						class="inputBox"
						auto-complete="new-password"
					/>
					<span class="show-pwd" @click="showoldPwd">
						<svg-icon icon-class="eye" />
					</span>
				</el-form-item>
				<el-form-item label="新密码" label-width="150px" prop="newPs">
					<el-input
						v-model="sendForm.newPs"
						placeholder="请输入新密码"
						:type="psnewType"
						class="inputBox"
						auto-complete="new-password"
					/>
					<span class="show-pwd" @click="shownewPwd">
						<svg-icon icon-class="eye" />
						<!-- <svg-icon icon-class="cc-eye" /> -->
					</span>
				</el-form-item>
				<el-form-item label="确认密码" label-width="150px" prop="surePs">
					<el-input
						v-model="sendForm.surePs"
						placeholder="请输入确认密码"
						:type="psureType"
						class="inputBox"
						auto-complete="new-password"
					/>
					<span class="show-pwd" @click="showsurePwd">
						<svg-icon icon-class="eye" />
					</span>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="SendDialogVisible=false">取 消</el-button>
				<el-button type="primary" @click="SubmitSend">确认</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
import { mapGetters } from "vuex";
import { generateTitle } from "@/utils/i18n";
import ScrollPane from "@/components/ScrollPane";

export default {
	props: {
		mySelectMenu: {
			type: String,
			default: "",
		},
		// IsgetIshowmenu:{
		// 	type: Boolean,
		// 	default:true
		// }
	},
	components: {
		ScrollPane,
	},
	data () {
		return {
			visible: false,
			top: 0,
			left: 0,
			selectedTag: {},
			activeIndex: "0",
			SendDialogVisible: false,
			loading: false,
			psoldType: "password",
			psnewType: "password",
			psureType: "password",
			rules: {
				oldPs: [
					{
						required: true,
						message: "请输入旧密码",
						trigger: "blur",
					},
				],
				newPs: [
					{
						required: true,
						message: "请输入新密码",
						trigger: "blur",
					},
				],
				surePs: [
					{
						required: true,
						message: "请输入确认密码",
						trigger: "blur",
					},
				],
			},
			sendForm: {
				oldPs: "",
				newPs: "",
				surePs: "",
			},
			routerList: [],
			localPageLogo: "",
			localPageName: "",
			localPagePhone: "",
			localPageWxurl: "",
			localpageRoles: '',
			isHeadens: false,
			ComponentId: null,
		};
	},
	computed: {
		...mapGetters([
			"name",
			"MallName",
			"mallPhone",
			"MallAvatar",
			"permission_routers",
			"ShopLogo",
		]),
		visitedViews () {
			return this.$store.state.tagsView.visitedViews;
		},
	},
	created () {
		this.ComponentId = localStorage.getItem("mlmzpageComponentId");
	},
	watch: {
		$route () {
			this.addViewTags();
			this.moveToCurrentTag();
		},
		visible (value) {
			if (value) {
				document.body.addEventListener("click", this.closeMenu);
			} else {
				document.body.removeEventListener("click", this.closeMenu);
			}
		},
		mySelectMenu (value) {
			let menueLength = value.split("/");
			let newRouter = "/" + (menueLength[1] ? menueLength[1] : "");
			this.activeIndex = newRouter == "/home" ? "/" : newRouter;
		},
	},
	mounted () {
		this.localPageLogo = localStorage.getItem("mlmzpageLogo");
		this.localPageName = localStorage.getItem("mlmzpageName");
		this.localPagePhone = localStorage.getItem("mlmzpagePhone");
		this.localPageWxurl = localStorage.getItem("mlmzpageWxurl");
		this.localpageRoles = localStorage.getItem("mlmzpageRoles");

		this.$store.dispatch("delAllViews");
		// this.$router.push('/')
		this.routerList = [];
		// let routerAlldata = this.$router.options.routes.concat(this.permission_routers)

		this.permission_routers.map((record) => {
			if (record.meta && record.meta.title) {
				this.routerList.push(record);
			}
		});

		// console.log(this.$router,'这个是啥子')
		// this.activeIndex = this.$router.currentRoute.matched[0].path ? this.$router.currentRoute.matched[0].path : '/';
		// this.routerList.map(item => {
		// 	if (item.path == this.activeIndex) {
		// 		this.$emit('getRourterList', item)
		// 	}

		// })
		let menu = {
			children: this.routerList,
			meta: {},
			path: "/",
		};
		// console.log(menu);
		this.$emit("getRourterList", menu);
		this.addViewTags();
	},
	methods: {
		// 下拉框的方法
		handleCommand (command) {
			if (command == 1) {
				this.changePasswaord();
			} else if (command == 2) {
				this.logout();
			}
		},

		// 点击父级菜单
		parentsRouter (item) {
			let pathpage = "";
			if (item.children[0].children && item.children[0].children.length) {
				pathpage = item.children[0].children[0].path;
			} else {
				pathpage =
					item.children[0].path == "home" ? "/" : item.children[0].path;
			}

			// if(item.children.length==1){
			this.$router.push({
				path: pathpage,
			});

			// }
		},

		// 客服
		goCustmer () {
			window.open(
				"http://lastonepcapitest.xiaojiankeji.com/swagger-ui/#!/auth/Authenticate_0"
			);
		},

		//确认
		SubmitSend () {
			this.$refs.sendForm.validate((valid) => {
				if (valid) {
					if (this.sendForm.newPs != this.sendForm.surePs) {
						this.$message({
							showClose: true,
							message: "密码不一致",
							type: "error",
						});
						return false;
					}
					this.loading = true;
					this.$store
						.dispatch("ChangePass", this.sendForm)
						.then((res) => {
							if (res.success) {
								this.$message({
									showClose: true,
									type: "success",
									message: "修改成功，请重新登录",
								});
								this.loading = false;
								this.logout();
							} else {
								this.$message({
									showClose: true,
									type: "error",
									message: res.alertMsg,
								});
							}
						})
						.catch(() => {
							this.loading = false;
						});
				} else {
					console.log("error submit!!");
					return false;
				}
			});
		},

		//显示旧密码
		showoldPwd () {
			if (this.psoldType === "password") {
				this.psoldType = "";
			} else {
				this.psoldType = "password";
			}
		},
		shownewPwd () {
			if (this.psnewType === "password") {
				this.psnewType = "";
			} else {
				this.psnewType = "password";
			}
		},
		showsurePwd () {
			if (this.psureType === "password") {
				this.psureType = "";
			} else {
				this.psureType = "password";
			}
		},

		logout () {
			this.$store.dispatch("LogOut").then(() => {
				location.reload(); // In order to re-instantiate the vue-router object to avoid bugs
			});
		},

		//修改密码
		changePasswaord () {
			this.SendDialogVisible = true;
			this.sendForm.oldPs = "";
			this.sendForm.newPs = "";
			this.sendForm.surePs = "";
		},

		handleSelect (key, keyPath) {
			console.log(key);
			console.log(keyPath);
			// this.routerList.map(items => {
			// 	if (items.path == key) {
			// 		this.$emit('getRourterList', items)
			// 	}
			// })
		},
		generateTitle, // generateTitle by vue-i18n
		generateRoute () {
			if (this.$route.name) {
				return this.$route;
			}
			return false;
		},
		isActive (route) {
			// this.routerList.map(items => {
			// 	if (items.path == this.$route.matched[0].path) {
			// 		this.$emit('getRourterList', items)
			// 	}

			// })

			this.$emit("selctMenu", this.$route.path);
			return route.path === this.$route.path;
		},
		addViewTags () {
			const route = this.generateRoute();

			if (!route) {
				return false;
			}
			// console.log(route);
			this.$store.dispatch("addVisitedViews", route);
		},
		moveToCurrentTag () {
			const tags = this.$refs.tag;
			this.$nextTick(() => {
				for (const tag of tags) {
					if (tag.to.path === this.$route.path) {
						this.$refs.scrollPane.moveToTarget(tag.$el);
						break;
					}
				}
			});
		},
		closeSelectedTag (view) {
			this.$store.dispatch("delVisitedViews", view).then((views) => {
				if (this.isActive(view)) {
					const latestView = views.slice(-1)[0];
					if (latestView) {
						this.$router.push(latestView);
					} else {
						this.$router.push("/");
					}
				}
			});
		},
		closeOthersTags () {
			this.$router.push(this.selectedTag);
			this.$store.dispatch("delOthersViews", this.selectedTag).then(() => {
				this.moveToCurrentTag();
			});
		},
		closeAllTags () {
			this.$store.dispatch("delAllViews");
			this.$router.push("/");
		},
		openMenu (tag, e) {
			this.visible = true;
			this.selectedTag = tag;
			const offsetLeft = this.$el.getBoundingClientRect().left; // container margin left
			this.left = e.clientX - offsetLeft + 15; // 15: margin right
			this.top = e.clientY;
		},
		closeMenu () {
			this.visible = false;
		},
		// 关闭所有
		handleTagsClose () {
			this.$store.state.tagsView.visitedViews = [];
			this.$router.push({
				name: "home",
			});
		},

		// 关闭其他
		handleCloseother () {
			this.$store.state.tagsView.visitedViews = this.$store.state.tagsView.visitedViews.filter(
				(item) => {
					return item.path == this.$route.path;
				}
			);
		},
	},
};
</script>

<style lang="less" scoped>
.show-pwd {
	position: absolute;
	right: 20px;
}

.right-menu-people {
	float: right;
	height: 100%;
	display: -webkit-box;
	display: -moz-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
	background: #1a2533;
	line-height: 60px;
	height: 60px;
	color: #fff;

	.addBtn {
		span {
			cursor: pointer;
			font-size: 14px;
			margin-right: 30px;
		}
	}

	&:focus {
		outline: none;
	}

	.right-menu-item {
		display: inline-block;
		margin: 0 8px;
	}

	.screenfull {
		height: 20px;
	}

	.international {
		vertical-align: top;
	}

	.theme-switch {
		vertical-align: 15px;
	}

	.avatar-container {
		height: 50px;

		.avatar-wrapper {
			cursor: pointer;
			margin-top: 10px;
			position: relative;
			display: flex;
			flex-direction: row;
			align-items: center;

			.mall-message {
				// div{
				// 	cursor: pointer;
				font-size: 14px;
			}

			.user-avatar {
				width: 30px;
				height: 30px;
				border-radius: 100%;
			}

			// .el-icon-arrow-right
			.el-icon-caret-bottom {
				position: absolute;
				right: -20px;
				top: 10px;
				font-size: 12px;
			}
		}
	}
}

.login-passworld {
	background: #304156;
	height: 60px;
	line-height: 60px;
	color: #fff;
	padding-right: 60px;
}

.tags-view-container {
	width: 100%;

	.view-container-childs {
		position: fixed;
		width: 100%;
		left: 0;
		top: 0;
		z-index: 5;
	}

	.tags-view-wrapper {
		background: #fff;
		height: 36px;
		border-bottom: 1px solid #d8dce5;
		box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12),
			0 0 3px 0 rgba(0, 0, 0, 0.04);
		margin-left: 180px;

		.tags-view-item {
			display: inline-block;
			position: relative;
			height: 26px;
			line-height: 26px;
			border: 1px solid #d8dce5;
			color: #495060;
			background: #fff;
			padding: 0 8px;
			font-size: 12px;
			margin-left: 5px;
			margin-top: 5px;

			&:first-of-type {
				margin-left: 15px;
			}

			&.active {
				background-color: #2d8cf0;
				color: #fff;
				border-color: #2d8cf0;

				&::before {
					content: "";
					background: #fff;
					display: inline-block;
					width: 8px;
					height: 8px;
					border-radius: 50%;
					position: relative;
					margin-right: 2px;
				}
			}
		}
	}

	// .tags-view-wrapperishow{
	// 	margin-left: 180px;
	// }

	.contextmenu {
		margin: 0;
		background: #fff;
		z-index: 100;
		position: absolute;
		list-style-type: none;
		padding: 5px 0;
		border-radius: 4px;
		font-size: 12px;
		font-weight: 400;
		color: #333;
		box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.3);

		li {
			margin: 0;
			padding: 7px 16px;
			cursor: pointer;

			&:hover {
				background: #eee;
			}
		}
	}
}
</style>

<style lang="less">
//reset element css of el-icon-close
.tags-view-wrapper {
	.tags-view-item {
		.el-icon-close {
			width: 16px;
			height: 16px;
			vertical-align: 2px;
			border-radius: 50%;
			text-align: center;
			transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
			transform-origin: 100% 50%;

			&:before {
				transform: scale(0.6);
				display: inline-block;
				vertical-align: -3px;
			}

			&:hover {
				background-color: #b4bccc;
				color: #fff;
			}
		}
	}
}
.isNoShowzj {
	width: 0 !important;
	padding: 0 !important;
	opacity: 0;
	position: relative;
	z-index: -1;
}

.mfl-right-buttons {
	position: absolute;
	right: 10px;
	top: 63px;
}
</style>
