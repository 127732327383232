import request from '@/utils/request'

// 获取路由菜单

export const MenuInit = () => request('/admin/system/index');
export const supplierproductList = (data) => request('/admin/supplierproduct/list', data);
//菜单添加
export const addAuthorities = (data) => request('/admin/menu/addAuthorities', data);
//菜单编辑
export const editAuthorities = (data) => request('/admin/menu/editAuthorities', data);
//菜单删除
export const deleteAuthorities = (data) => request('/admin/menu/deleteAuthorities', data);



// 商品
// 商品列表
export const productList = (data) => request('/admin/goods/getPagedList', data);

// 商品上下架
export const productOpen = (data) => request('/admin/goods/updateShelfStatus', data);

// 商品删除
export const productDelete = (data) => request('/pc/product/delete', data);

// 品牌筛选列表
export const productBrandList = (data) => request('/pc/productBrand/filterBoxList', data);

// 分组筛选列表
export const productGroupList = (data) => request('/pc/productGroup/filterBoxList', data);

// 获取商品规格信息
export const productSpecGet = (data) => request('/pc/productSpec/get', data);

// 商品编辑名称、编码
export const productChangeNameAndNo = (data) => request('/pc/product/changeNameAndNo', data);

// 商品编辑价格
export const productChangePrice = (data) => request('/admin/goods/updateGoodsPrice', data);

// 商品编辑销量
export const productChangeSoldCount = (data) => request('/admin/goods/updateVirtualSoldCount', data);

// 商品编辑热力值
export const updateHeatValue = (data) => request('/admin/goods/heatValue/update', data);

// 商品出入库
export const productSpeChangeStock = (data) => request('/admin/goods/updateGoodsStock', data);

// 商品批量上下架
export const productBatchOpen = (data) => request('/pc/product/batchOpen', data);

// 商品批量删除
export const productBatchDelete = (data) => request('/pc/product/batchDelete', data);

// 商品获取历史规格名
export const productSpecSpecTitleList = () => request('/pc/productSpec/specTitleList');

// 商品获得某一规格下的历史规格
export const productSpecSpecValueListe = (data) => request('/pc/productSpec/specValueList', data);

// 商品保存
export const productSave = (data) => request('/admin/goods/publishGoods', data);
// 商品编辑
export const productEdit = (data) => request('/admin/goods/editGoods', data);
// 商品统计
export const productCount = (data) => request('/admin/goods/goodsCount', data);
// 商品导出
export const goodsExport = (data) => request('/admin/goods/export', data);
// 商品删除
export const productDel = (data) => request('/admin/goods/del', data);

// 商品 子商品列表
export const productSpecList = (data) => request('/pc/productSpec/list', data);

// 类目筛选框列表
export const productCategoryLibraryList = () => request('/pc/productCategoryLibrary/filterBoxList');


// 推手等级
export const distributorRoleFilterBoxList = () => request('/pc/distributorRole/filterBoxList');

// 商品信息
export const productInfo = (data) => request('/admin/goods/getGoodsDetailsToEdit', data);

// 商品修改收益与提成
export const changeSellReward = (data) => request('/pc/product/changeSellReward', data);

// 商品-批量修改分组初始化
export const batchChangeGroupInit = (data) => request('/pc/product/batchChangeGroupInit', data);

//  商品-批量修改分组
export const batchChangeGroup = (data) => request('/pc/product/batchChangeGroup', data);

// 批量修改 是否加入购物车
export const productBatchAddCart = (data) => request('/pc/product/batchAddCart', data);

// 页面管理编辑-初始化
export const editSelectinit = (data) => request('/pc/mall-shop-decorate/edit-select-product-init', data);

// 修改商品虚拟销量初始化
export const changeVirtualSalesInit = (data) => request('/pc/product/changeVirtualSalesInit', data);

// 修改商品虚拟销量
export const changeVirtualSales = (data) => request('/pc/product/changeVirtualSales', data);

// 批量修改商品虚拟销量
export const batchChangeVirtualSales = (data) => request('/pc/product/batchChangeVirtualSales', data);

// 商品-单个修改供货市场状态
export const changeSupplierState = (data) => request('/pc/product/changeSupplierState', data);

// 商品-批量修改供货市场状态
export const batchChangeSupplierState = (data) => request('/pc/product/batchChangeSupplierState', data);






// 分组管理

// 分组列表
export const productGroupListData = (data) => request('/admin/goodsGroup/getList', data);

// 分组导出
export const goodsGroupExport = (data) => request('/admin/goodsGroup/export', data);

// 分组保存
export const productGroupSave = (data) => request('/admin/goodsGroup/save', data);

// 分组删除
export const productGroupDelete = (data) => request('/admin/goodsGroup/del', data);

//分组页面获取商品列表
export const getGoodsListByGroupId = (data) => request('/admin/goodsGroup/getGoodsListByGroupId', data);

//删除分组内商品
export const groupDelGoods = (data) => request('/admin/goodsGroup/delGoods', data);

//分组内商品修改排序值
export const updateGoodsOrder = (data) => request('/admin/goodsGroup/updateGoodsOrder', data);

// 品牌

// 品牌列表
export const productBrandListAll = (data) => request('/pc/productBrand/list', data);

// 品牌保存
export const productBrandListAllsave = (data) => request('/pc/productBrand/save', data);

// 品牌删除
export const productBrandListAlldelete = (data) => request('/pc/productBrand/delete', data);

// 订单

// 订单列表
export const orderIndex = (data) => request('/admin/order/findAllPage', data);

// 订单统计
export const orderStatistics = (data) => request('/admin/order/statistics', data);

// 订单列表初始化
export const orderIndexinit = () => request('/pc/order/index-init');

// 订单关闭
export const orderIndexClose = (data) => request('/pc/order/close', data);

// 加星
export const orderIndexStar = (data) => request('/pc/order/star', data);

// 订单发货
export const orderSend = (data) => request('/pc/order/send', data);

// 订单查看物流
export const orderlookExpress = (data) => request('/pc/order/look-express', data);

// 订单备注
export const orderRemark = (data) => request('/admin/order/addOrderAdminRemark', data);

// 订单审单
export const orderCheck = (data) => request('/pc/order/check', data);

// 批量审单
export const orderbatchCheck = () => request('/pc/order/batch-check');

// 批量审单 获取进度条
export const ordergetProgress = () => request('/pc/order/batch-check-get-progress');

// 订单详情
export const orderlookDetail = (data) => request('/admin/order/findOrderDetail', data);

// 包裹轨迹
export const selectChildOrderTark = (data) => request('/admin/order/selectChildOrderTark', data);

// 订单修改收货信息
export const ordereditReceive = (data) => request('/pc/order/edit-receive', data);

// 订单主动退款初始化
export const ordererefundInit = (data) => request('/pc/order/refund-init', data);

// 订单主动退款保存
export const ordererefundsave = (data) => request('/admin/afterSale/refund', data);

// 修改用户实名身份信息
export const updateUserCard = (data) => request('/admin/order/updateUserCard', data);

// 查询物流
export const findLogistics = (data) => request('/admin/order/findLogistics', data);

// 商品详情组合商品
export const detailcomboProduct = (data) => request('/pc/order/look-detail-combo-product', data);

// 订单详情优惠明细
export const detailcomboDetail = (data) => request('/pc/order/look-detail-reduce-detail', data);

// 订单修改配送方式保存
export const editendTypesave = (data) => request('/pc/order/edit-send-type-save', data);

// 订单改价初始化
export const editPriceinit = (data) => request('/pc/order/edit-price-init', data);

// 订单改价保存
export const editPricesave = (data) => request('/pc/order/edit-price-save', data);

// 订单导入发货获取进度信息
export const orderimportGetprogress = (data) => request('/pc/order/order-import-send-get-progress', data);

// 订单导入发货获取进度信息
export const orderRefundAll = (data) => request('/admin/afterSale/all/refund', data);

// 售后换货选择商品列表
export const afterSaleGoodsList = (data) => request('/admin/afterSale/goods/list', data);

// 售后换货选择商品 商品分组列表
export const afterSaleGoodsGroupList = (data) => request('/admin/afterSale/goods/group/list', data);

//发货
export const orderDeliver = (data) => request('/admin/order/deliver', data);
//获取发货详情
export const deliverDetial = (data) => request('/admin/order/deliver/detail', data);

//供应商批量发货
//订单发货记录
export const deliverList = (data) => request('/admin/order/deliver/list', data);

//发货订单导出
export const deliverExport = (data) => request('/admin/order/deliver/export', data);

//订单批量发货
export const deliverBatch = (data) => request('/admin/order/deliver/batch', data);

//发货明细列表
export const importList = (data) => request('/admin/order/import/list', data);

//订单导出
export const orderExport = (data) => request('/admin/order/export', data);


/**
 * 限时折扣
 */
//列表
export const timelimitdiscountlist = (data) => request('/admin/activity/findAll', data);
//编辑
export const timelimitdiscountedit = (data) => request('/admin/activity/addOrUpActivity', data);
//详情
export const timelimitdiscountdetail = (data) => request('/admin/activity/findById', data);
//删除
export const timelimitdiscountdelete = (data) => request('/admin/activity/delActivityDiscount', data);
//失效
export const timelimitdiscountsetisopen = (data) => request('/pc/timelimitdiscount/timelimitdiscountsetisopen', data);
//产品弹框
export const discountchoseproductlist = (data) => request('/pc/timelimitdiscount/discountchoseproductlist', data);


/**
 * 打包一口价
 */
//列表
export const activityBaleList = (data) => request('/pc/activity-bale/index', data);
//弹框商品列表
export const editProduct = (data) => request('/pc/activity-bale/edit-product', data);
//编辑初始化
export const editInit = (data) => request('/pc/activity-bale/edit-init', data);
//保存编辑
export const editSave = (data) => request('/pc/activity-bale/edit-save', data);
//使失效
export const baleClose = (data) => request('/pc/activity-bale/close', data);
//删除
export const baleDelete = (data) => request('/pc/activity-bale/delete', data);


// 运费列表





// 包邮工具

// 包邮列表
export const activityFreeDeliveryList = (data) => request('/pc/activityFreeDelivery/list', data);

export const activityFreeDeliveryproductList = (data) => request('/pc/activityFreeDelivery/productList', data);

// 包邮工具使终止
export const activityFreeDeliveryopen = (data) => request('/pc/activityFreeDelivery/open', data);

// 包邮工具 删除
export const activityFreeDeliveryDelete = (data) => request('/pc/activityFreeDelivery/delete', data);

// 包邮工具保存
export const activityFreeDeliverySave = (data) => request('/pc/activityFreeDelivery/save', data);

// 包邮工具 活动信息
export const activityFreeDeliveryInfo = (data) => request('/pc/activityFreeDelivery/info', data);


/**
 * 运费模板
 */
export const freightTemplateList = (data) => request('/admin/freightTemplate/getList', data);

// 运费模板删除
export const freightTemplatedeletet = (data) => request('/admin/freightTemplate/del', data);

// 运费模板详情
export const freightTemplateinfo = (data) => request('/admin/freightTemplate/getDetail', data);

// 运费模板保存
export const freightTemplateSave = (data) => request('/admin/freightTemplate/save', data);


/**
 * 会员等级
 */

// 会员等级主页
export const memberVipRoleIndex = () => request('/pc/memberVipRole/index');

// 会员等级信息
export const memberVipRoleInfo = (data) => request('/pc/memberVipRole/info', data);

// 优惠券弹框
export const activitycoupoList = (data) => request('/pc/activitycoupon/popupList', data);

// 会员等级保存
export const activitycoupoSave = (data) => request('/pc/memberVipRole/save', data);

// 开启关闭微信卡
export const changeisopenwxvipcard = (data) => request('/pc/wxvipcard/changeisopenwxvipcard', data);

// 创建会员卡(提交审核)
export const wxvipcardCreatevipcard = (data) => request('/pc/wxvipcard/createvipcard', data);

// 自定义菜单
// 获取公众号菜单
export const getWxMenu = () => request('/pc/wxMenu/getWxMenu');

// 保存公主号菜单
export const saveWxMenu = (data) => request('/pc/wxMenu/saveWxMenu', data);

// 生成公主号菜单
export const createWxMenu = (data) => request('/pc/wxMenu/createWxMenu', data);

// 客服
// 自助菜单
// 自助菜单列表
export const chatCustomerMenuList = () => request('/pc/chatCustomerMenu/list');

// 自助菜单保存
export const chatCustomerMenuSave = (data) => request('/pc/chatCustomerMenu/save', data);

// 工作量
export const customWorkloadList = (data) => request('/pc/customStatis/workloadList', data);

// 小尖商户平台商户网关
export const xjmallplatformgateway = (data) => request('/xjmallplatform/gateway', data);

// 销售业绩
export const customStatiSalesList = (data) => request('/pc/customStatis/salesList', data);


// 数据统计

// 数据统计-流量概览
export const dataStatisFlowOverview = (data) => request('/pc/dataStatis/flowOverview', data);

// 商品分析
// 商详页流量概况
export const dataProductDetailOverview = (data) => request('/pc/dataStatisProductDetail/flowOverview', data);

// 商品详情页-商品信息
export const dataProductDetailOverviewIndex = (data) => request('/pc/dataStatisProductDetail/index', data);

// 商品交易分析
// 商品交易概况
export const dataStatisProductSellAnalyzesell = (data) => request('/pc/dataStatisProductSellAnalyze/sellOverview', data);

// 商品交易分析
export const dataStatisProductSellAnalyzeindex = (data) => request('/pc/dataStatisProductSellAnalyze/index', data);

// 单品分析-商品分析
export const dataStatisProductInfo = (data) => request('/pc/dataStatis/skuAnalyze/skuAnalyzeProductInfo', data);

// sku-销售分析
export const dataStatisSkuSaleAnalyze = (data) => request('/pc/dataStatis/skuAnalyze/skuSaleAnalyze', data);

// 复购分析
export const dataStatisRepeatPayAnalyze = (data) => request('/pc/dataStatis/skuAnalyze/repeatPayAnalyze', data);

// 直播间列表
export const liveRoomList = (data) => request('/admin/live/findAll', data);

// 直播间-获取最新直播间
export const liveRoomRefreshCache = () => request('/admin/live/getLiveInfo');

// 直播间-是否展示在首页
export const liveRoomisShow = (data) => request('/pc/liveRoom/isShow', data);

// 直播间删除
export const liveRoomDelete = (data) => request('/admin/live/delLiveRoom', data);

//直播间交易数据
export const LiveroomTradedata = (data) => request('/admin/live/selectTatol', data);

//直播间回放
export const getLiveReplay = (data) => request('/admin/live/getLiveReplay', data);

//直播间修改主播身份
export const updateAuthor = (data) => request('/admin/live/updateAuthor', data);

//关联主播
export const updateBindUser = (data) => request('/admin/live/updateBindUser', data);

//查询代理的基本信息
export const getAgentInfo = (data) => request('/admin/agentInfo/getAgentInfo', data, 'get');


// 门店业绩列表
export const shopPerformanceindex = (data) => request('/pc/shop-performance/index', data);

// 门店业绩详情支付订单列表
export const shopPerformanceorder = (data) => request('/pc/shop-performance/order-index', data);

// 门店业绩列表初始化
export const shopPerformanceInt = () => request('/pc/shop-performance/init');

// 门店业绩退款单
export const shopPerformanceRefund = (data) => request('/pc/shop-performance/refund-index', data);


// 店员业绩
export const employeePerformanceList = (data) => request('/pc/employeePerformance/list', data);

// 销售明细-退款单-列表
export const employeSupportList = (data) => request('/pc/employeePerformance/supportList', data);

// 商品导入

// 商品导入记录列表
export const productImportRecordList = (data) => request('/pc/productImportRecord/list', data);

// 接口配置
export const mallerponfig = () => request('/pc/mall-erp-config/index');

// 接口配置生成密钥
export const mallerponfigGenerate = () => request('/pc/mall-erp-config/generate');

// 接口密钥启用
export const mallerponfigtartuse = () => request('/pc/mall-erp-config/start-use');

// 接口配置重置密钥
export const mallerponfigReset = () => request('/pc/mall-erp-config/reset');

// 接口配置回调地址保存
export const mallerponfigallbackurl = (data) => request('/pc/mall-erp-config/save-callback-url', data);

// 物流助手
// 打单列表
export const expressAssistantorder = (data) => request('/pc/wx-express-assistant-order/index', data);

// 打单列表展开详情
export const expressAssistantDetail = (data) => request('/pc/wx-express-assistant-order/extend-detail', data);

// 组合详情
export const expressAssistantextenmix = (data) => request('/pc/wx-express-assistant-order/extend-mix', data);

// 批量打单
export const expressAssistanBatchpush = (data) => request('/pc/wx-express-assistant-order/batch-push', data);

// 打单列表初始化
export const expressAssistanInit = (data) => request('/pc/wx-express-assistant-order/init', data);

// 获取银行卡列表
export const commissionWithdrawalList = () => request('/pc/commissionWithdrawal/bankList');

// 获取锁粉客户各个类型数量
export const memberLockFansTypeCount = (data) => request('/pc/member/memberLockFansTypeCount', data);

// 获取分销员锁粉页面
export const memberLockFansPageInit = (data) => request('/pc/member/memberLockFansPageInit', data);

// 获取提现人当前账号
export const commissionWithdrawalAccount = (data) => request('/pc/commissionWithdrawal/withdrawalAccount', data);

// 直播间修改状态
export const liveRoomUpdate = (data) => request('/pc/liveRoom/update', data);

// 个人中心配置
// 个人中心配置获取
export const mallMemberCenterBaseInfo = (data) => request('/pc/mall/mallMemberCenterBaseInfo', data);

// 个人中心配置保存
export const mallMemberCenterEdit = (data) => request('/pc/mall/mallMemberCenterEdit', data);

// 提现中心，页面初始化
export const commissionWithdrawalInt = () => request('/pc/commissionWithdrawal/init');


// 直播修改时间
export const liveRoomupdate = (data) => request('/pc/liveRoom/update', data);

// 支付有礼
export const activityPayGiftList = (data) => request('/pc/activityPayGift/list', data);

// 支付有礼删除
export const activityPayGiftDelete = (data) => request('/pc/activityPayGift/delete', data);

// 支付有礼使终止
export const activityPayGiftOpen = (data) => request('/pc/activityPayGift/open', data);

// 支付有礼保存
export const activityPayGiftSave = (data) => request('/pc/activityPayGift/save', data);

// 支付有礼详情
export const activityPayGiftInfo = (data) => request('/pc/activityPayGift/info', data);

//抽奖活动-弹窗列表
export const activityWheelSurfPopupList = (data) => request('/pc/activityWheelSurf/popupList', data);


//获取商品类目数据
export const categoryList = (data) => request('/admin/category/getList', data, 'get');

//根据Id删除类目
export const categoryDel = (data) => request('/admin/category/del', data, );

//商品类目数据 保存
export const categorySave = (data) => request('/admin/category/save', data, );

//分页获取规格库列表
export const getSpecList = (data) => request('/admin/spec/getPagedList', data, );

//规格库 保存规格
export const saveSpec = (data) => request('/admin/spec/saveSpec', data, );

//获取供应商分页数据
export const supplierGetList = (data) => request('/admin/supplier/getList', data, );

//根据Id删除供应商
export const supplierDel = (data) => request('/admin/supplier/del', data, );

//供应商编辑 保存
export const supplierSave = (data) => request('/admin/supplier/save', data, );

//根据Id规格 删除规格
export const deleteSpec = (data) => request('/admin/spec/del', data, );

/**
 * 退款原因管理
 */
//新增
export const addAfterSalesReasons = (data) => request('/admin/refundReason/add', data, );
//删除
export const delAfterSalesReasons = (data) => request('/admin/refundReason/del', data, );
//列表
export const getAfterSalesReasons = (data) => request('/admin/refundReason/list', data, );
//修改
export const updateAfterSalesReasons = (data) => request('/admin/refundReason/update', data, );

//修改收货地址
export const updateAddress = (data) => request('/admin/order/address/update', data, );


/**
 * 秒杀活动api
 */
//秒杀活动列表
export const seckillList = (data) => request('/admin/seckill/list', data, );

//秒杀活动详情
export const seckillDetail = (data) => request('/admin/seckill/detail', data, );

//秒杀活动选择列表
export const seckillGoodsList = (data) => request('/admin/seckill/goods/list', data, );

//添加秒杀活动
export const seckillUpdate = (data) => request('/admin/seckill/update', data, );

//秒杀活动商品信息保存或更新
export const seckillGoodsUpdate = (data) => request('/admin/seckill/goods/update', data, );

//编辑详情
export const seckilleditDetail = (data) => request('/admin/seckill/editDetail', data, );

//规格列表
export const seckillSkuList = (data) => request('/admin/seckill/sku/list', data, );

//秒杀结束
export const seckillSend = (data) => request('/admin/seckill/end', data, );

//秒杀商品删除
export const seckillGoodsDel = (data) => request('/admin/seckill/goods/del', data, );

//秒杀sku删除
export const seckillSkuDel = (data) => request('/admin/seckill/goods/sku/del', data, );

/**
 * 推广素材api
 */
//添加素材
export const addMaterial = (data) => request('/admin/material/addMaterial', data, );

//删除素材
export const delMaterial = (data) => request('/admin/material/delMaterial', data, );

//查询素材列表
export const findAllMaterial = (data) => request('/admin/material/findAll', data, );

//查询素材
export const findMaterialById = (data) => request('/admin/material/findById', data, );

//修改素材
export const updateMaterial = (data) => request('/admin/material/updateMaterial', data, );

//获取基础设置
export const getBasicsSetting = (data) => request('/admin/sysConfig/getBasicsSetting',data,'get');

//保存基础设置
export const saveBasicsSetting = (data) => request('/admin/sysConfig/saveBasicsSetting',data);


/**
 * 专题活动api
 */
//专题活动list
export const subjectActivityList = (data) => request('/admin/subjectActivity/findAll',data);
//结束专题活动
export const subjectfinishActIds = (data) => request('/admin/subjectActivity/delActIds',data);
//专题活动详情
export const subjectActivityfindByActId = (data) => request('/admin/subjectActivity/findByActId',data);
//添加专题活动
export const subjectActivitysaveActivity = (data) => request('/admin/subjectActivity/saveActivity',data);
//修改专题活动
export const subjectActivityupdateActivity = (data) => request('/admin/subjectActivity/updateActivity',data);

/**
 * cps系统接口
 */
//获取必应鸟商品目录
export const getCpsGoods = (data) => request('/admin/cps/goods/list',data);
//验证是否已经添加为商品
export const cpsValidate = (data) => request('/admin/cps/goods/validate',data);
//获取商品列表
export const getCpsGoodsList = (data) => request('/admin/goods/cps/list',data);
//获取商品列表统计
export const cpsGoodsCount = (data) => request('/admin/goods/cps/goodsCount',data);
//获取商品列表导出
export const cpsGoodsExport = (data) => request('/admin/goods/cps/export',data);
/**
 * API商品
 */
//查询API商品列表查询条件
export const getApiGoodsCondition = (data) => request('/admin/api/goods/apiGoodsCondition',data);
//查询API商品池列表
export const queryApiGoodsInfoList = (data) => request('/admin/api/goods/queryApiGoodsInfoList',data);

/**
 * 增值服务/服务承诺
 */
//获取服务承诺列表
export const getPromiseList = (data) => request('/admin/promise/findPage',data);
//添加服务承诺
export const addPromise = (data) => request('/admin/promise/addPromise',data);
//编辑服务承诺
export const updatePromise = (data) => request('/admin/promise/updatePromise',data);
//删除服务承诺
export const delPromise = (data) => request('/admin/promise/delPromise',data);

//获取服务承诺列表
export const getAppreciatList = (data) => request('/admin/appreciat/findPage',data);
//添加服务承诺
export const addAppreciat = (data) => request('/admin/appreciat/addAppreciat',data);
//编辑服务承诺
export const updateAppreciat = (data) => request('/admin/appreciat/updateAppreciat',data);
//删除服务承诺
export const delAppreciat = (data) => request('/admin/appreciat/delAppreciat',data);


/**礼包**/
//升级礼包列表
export const upgradeGiftList = (data) => request('/admin/upgradeGift/list',data);
//添加升级礼包
export const upgradeGiftAdd = (data) => request('/admin/upgradeGift/add',data);
//移除升级礼包
export const upgradeGiftRemove = (data) => request('/admin/upgradeGift/remove',data);
//设置礼包状态
export const setActiveStatus = (data) => request('/admin/upgradeGift/setActiveStatus', data);
//礼包页面获取商品列表
export const getPagedListForGift = (data) => request('/admin/goods/getPagedListForGift',data);

// 品牌

//添加品牌
export const addBrand = (data) => request('/admin/brand/addBrand',data);
//删除
export const delBrand = (data) => request('/admin/brand/delBrand',data);
//列表
export const findBrandPage = (data) => request('/admin/brand/findPage',data);
//修改品牌
export const updateBrand = (data) => request('/admin/brand/updateBrand',data);


//申诉相关
//申诉列表
export const findPageComplaint = (data) => request('/admin/complaint/findPageComplaint',data);
//申诉详情
export const findByComplaintId = (data) => request('/admin/complaint/findByComplaintId',data);
//审核申诉
export const updateComplaintStatus = (data) => request('/admin/complaint/updateComplaintStatus',data);


/**
 * 换购 **/

//新增或编辑换购活动
export const editActivitySwap = (data) => request('/admin/activity/editActivitySwap',data);
//查询加购活动详情
export const getActivitySwap = (data) => request('/admin/activity/getActivitySwap',data);
//获取商品简易详情以作修改
export const getGoodsSampleDetail = (data) => request('/admin/goods/getGoodsSampleDetail',data);
//分页查询换购活动列表
export const getActivitySwapPage = (data) => request('/admin/activity/getActivitySwapPage',data);
//分页查询换购商品列表
export const getActivitySwapGoodsPage = (data) => request('/admin/activity/getActivitySwapGoodsPage',data);
//修改换购商品状态
export const updateActivitySwapGoodsState = (data) => request('/admin/activity/updateActivitySwapGoodsState',data);
//修改换购活动状态
export const updateActivitySwapState = (data) => request('/admin/activity/updateActivitySwapState',data);


//抽奖活动
//添加活动
export const addDrawActivity= (data) => request('/admin/luckDraw/addActivity',data);
//活动-活动列表
export const drawactivityList= (data) => request('/admin/luckDraw/activityList',data);
//奖品-添加奖品
export const addPrize= (data) => request('/admin/luckDraw/addPrize',data);
//删除活动
export const drawdelActivity= (data) => request('/admin/luckDraw/delActivity',data);
//奖品-删除奖品
export const drawdelPrize= (data) => request('/admin/luckDraw/delPrize',data);
//抽奖活动商品列表
export const drawgoodsList= (data) => request('/admin/luckDraw/goodsList',data);
//活动-获取活动详情
export const drawloadActivity= (data) => request('/admin/luckDraw/loadActivity',data);
//奖品-获取奖品详情
export const loadPrizeDetail= (data) => request('/admin/luckDraw/loadPrizeDetail',data);
//奖品-获取奖品列表
export const loadPrizeList= (data) => request('/admin/luckDraw/loadPrizeList',data);
//抽奖-抽奖数据
export const lockDrawData= (data) => request('/admin/luckDraw/lockDrawData',data);
//活动-编辑活动
export const drawupdateActivity= (data) => request('/admin/luckDraw/updateActivity',data);
//奖品-编辑奖品
export const updatePrize= (data) => request('/admin/luckDraw/updatePrize',data);
//抽奖-商品列表
export const activityGoodsList= (data) => request('/admin/luckDraw/activityGoodsList',data);
//抽奖-选择优惠券
export const findForLuckDraw= (data) => request('/admin/luckDraw/findForLuckDraw',data);


//商品标签
//商品标签 -新增标签
export const addTags = (data) => request('/admin/goods/tags/add',data);
//商品标签 -批量删除
export const delTags = (data) => request('/admin/goods/tags/batch/del',data);
//商品标签 -商品标签列表
export const listTags = (data) => request('/admin/goods/tags/list',data);
//商品标签 -修改标签
export const editTags = (data) => request('/admin/goods/tags/update',data);


//拼团活动
//新增拼团活动
export const addGroupActivity = (data) => request('/admin/groupActivity/addGroupActivity',data);
//查看拼团活动
export const groupActivityView = (data) => request('/admin/groupActivity/groupActivityView',data);
//拼团活动数据详情
export const groupDataDetail = (data) => request('/admin/groupActivity/groupDataDetail',data);
//编辑拼团活动
export const editGroupActivity = (data) => request('/admin/groupActivity/editGroupActivity',data);

//删除拼团活动
export const deleteGroupActivity = (data) => request('/admin/groupActivity/deleteGroupActivity',data);
//拼团活动列表
export const groupActivityList = (data) => request('/admin/groupActivity/groupActivityList',data);
//拼团活动数据
export const groupData = (data) => request('/admin/groupActivity/groupData',data);
//拼团商品列表
export const groupGoodsList = (data) => request('/admin/groupActivity/groupGoodsList',data);
//终止拼团活动
export const stopGroupActivity = (data) => request('/admin/groupActivity/stopGroupActivity',data);
//导出拼团活动数据
export const exportGroupData = (data) => request('/admin/groupActivity/export',data);
