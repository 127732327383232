import Vue from 'vue'
import Router from 'vue-router'
/* Layout */
import Layout from '../views/layout/Layout'

Vue.use(Router)

// 固定路由表
export const constantRouterMap = [
	// component: () => import('./views/About.vue')
	{
		path: '/login',
		component: () => import('@/views/login/index'),
		hidden: true
	},
	{
		path: '/',
		component: Layout,
		redirect: 'home',
		meta: {
			title: 'home',
			icon: 'goods'
		},
		children: [{
			path: 'home',
			component: () => import('@/views/login/home'),
			name: 'home',
			meta: {
				title: 'home',
				// icon: 'drag'
			},
		}, ]
	},
]

export default new Router({
	scrollBehavior: () => ({
		y: 0
	}),
	routes: constantRouterMap
})

// 异步路由表，需判断用户角色权限

// if asyncRouterMap.children.length === 1 ,表示是没有子菜单的菜单,asyncRouterMap.children[0]的信息就显示在根菜单上
// 如果设置了 alwaysShow: true ,不管子菜单的数量是1还是更多,都显示为根菜单
export const asyncRouterMap = [
	//商品模块
	{
		path: '/goods',
		component: Layout,
		alwaysShow: true,
		meta: {
			title: 'goodsName',
			icon: 'goods'
		},
		// redirect: 'index',
		children: [{
				path: '/goods/creatGoodDetails',
				component: () => import('@/views/goods/creatGoodDetails'),
				name: 'creatGoodDetails',
				meta: {
					title: 'creatGoodDetails',
					icon: 'goods'
				},
			},
			{
				path: '/goods/editGoodDetails',
				component: () => import('@/views/goods/creatGoodDetails'),
				name: 'editGoodDetails',
				hidden: true,
				meta: {
					title: 'editGoodDetails',
					icon: 'goods'
				},
			},
			{
				path: '/goods/goodsList',
				component: () => import('@/views/goods/goodsList'),
				name: 'goodsList',
				meta: {
					title: 'goodsList',
					// icon: 'goods'
				}
			},
			{
				path: '/goods/goodsClass',
				component: () => import('@/views/goods/goodsClass'),
				name: 'goodsClass',
				meta: {
					title: 'goodsClass',
					// icon: 'goods'
				}
			},
			{
				path: '/goods/goodGroup',
				component: () => import('@/views/goods/goodGroup'),
				name: 'goodGroup',
				meta: {
					title: 'goodGroup',
					// icon: 'goods'
				}
			},
			{
				path: '/goods/comment',
				component: () => import('@/views/goods/comment'),
				name: 'comment',
				meta: {
					title: 'comment',
					// icon: 'goods'
				}
			},
			{
				path: '/goods/remindArrival',
				component: () => import('@/views/goods/remindArrival'),
				name: 'remindArrival',
				hidden: true,
				meta: {
					title: 'remindArrival',
					// icon: 'goods'
				}
			},
			{
				path: '/goods/goodsExport',
				component: () => import('@/views/goods/goodsExport'),
				name: 'goodsExport',
				hidden: true,
				meta: {
					title: 'goodsExport',
					// icon: 'goods'
				}
			},
			{
				path: '/goods/goodCategories',
				component: () => import('@/views/goods/goodCategories'),
				name: 'goodCategories',
				meta: {
					title: 'goodCategories',
					// icon: 'drag'
				}
			},
			// 供货商品	
			{
				path: '/goods/supplyGoods',
				component: () => import('@/views/goods/supplyGoods'),
				name: 'supplyGoods',
				meta: {
					title: 'supplyGoods'
				}
			},
			{
				path: '/goods/supplyMarket',
				component: () => import('@/views/goods/supplyMarket'),
				name: 'supplyMarket',
				meta: {
					title: 'supplyMarket'
				}
			},
			{
				path: '/goods/MeterialManagelist',
				component: () => import('@/views/goods/MeterialManagelist'),
				name: 'MeterialManagelist',
				hidden: true,
				meta: {
					title: 'MeterialManagelist'
				}
			},
			{
				path: '/goods/AddMeterialist',
				component: () => import('@/views/goods/AddMeterialist'),
				name: 'AddMeterialist',
				meta: {
					title: 'AddMeterialist'
				}
			},
			{
				path: '/goods/goodSpecList',
				component: () => import('@/views/goods/goodSpecList'),
				name: 'goodSpecList',
				meta: {
					title: 'goodSpecList'
				}
			},
			{
				path: '/goods/supplierManager',
				component: () => import('@/views/goods/supplierManager'),
				name: 'supplierManager',
				meta: {
					title: 'supplierManager'
				}
			},
			{
				path: '/goods/goodsGroupManager',
				component: () => import('@/views/goods/goodsGroupManager'),
				name: 'goodsGroupManager',
				hidden: true,
				meta: {
					title: 'goodsGroupManager'
				}
			},
			{
				path: '/goods/addSupplier',
				component: () => import('@/views/goods/addSupplier'),
				name: 'addSupplier',
				hidden: true,
				meta: {
					title: 'addSupplier'
				}
			},
			{
				path: '/goods/cps/cpsGoodsList',
				component: () => import('@/views/goods/cps/cpsGoodsList'),
				name: 'cpsGoodsList',
				hidden: true,
				meta: {
					title: 'cpsGoodsList'
				}
			},
			{
				path: '/goods/cps/cpsGoodsDetail',
				component: () => import('@/views/goods/cps/cpsGoodsDetail'),
				name: 'cpsGoodsDetail',
				hidden: true,
				meta: {
					title: 'cpsGoodsDetail'
				}
			},
			{
				path: '/goods/cps/cpsGoods',
				component: () => import('@/views/goods/cps/cpsGoods'),
				name: 'cpsGoods',
				hidden: true,
				meta: {
					title: 'cpsGoods'
				}
			},
			{
				path: '/goods/cps/vmsGoods',
				component: () => import('@/views/goods/cps/vmsGoods'),
				name: 'vmsGoods',
				hidden: true,
				meta: {
					title: 'vmsGoods'
				}
			},

			{
				path: '/goods/service/increment',
				component: () => import('@/views/goods/service/increment'),
				name: 'increment',
				meta: {
					title: 'increment'
				}
			},
			{
				path: '/goods/service/promise',
				component: () => import('@/views/goods/service/promise'),
				name: 'promise',
				meta: {
					title: 'promise'
				}
			},
			{
				path: '/goods/brand/index',
				component: () => import('@/views/goods/brand/index'),
				name: 'brand',
				meta: {
					title: 'brand'
				}
			},
			{
				path: '/goods/giftPack/index',
				component: () => import('@/views/goods/giftPack/index'),
				name: 'giftPack',
				meta: {
					title: 'giftPack'
				}
			}, {
				path: '/goods/goodsTag',
				component: () => import('@/views/goods/goodsTag'),
				name: 'goodsTag',
				meta: {
					title: 'goodsTag'
				}
			},
		]
	},
	//订单模块
	{
		path: '/order',
		component: Layout,
		meta: {
			title: 'order',
			icon: 'goods'
		},
		// redirect: 'index',
		children: [{
				path: '/order/orderMange',
				component: () => import('@/views/order/orderMangepage/orderMange'),
				name: 'orderMange',
				meta: {
					title: 'orderMange',
				}
			},
			{
				path: '/order/distributeOrder/distributePage',
				component: () => import('@/views/order/distributeOrder/distributePage'),
				name: 'distributePage',
				hidden: true,
				meta: {
					title: 'distributePage',
				}
			},
			//售后管理
			{
				path: '/order/refundAfterSale',
				component: () => import('@/views/treeMenu/threeMenupage'),
				name: 'refundAfterManage',
				meta: {
					title: 'refundAfterManage',
					isPage: true,
					url: '/order/refundAfterSale/refundAfterManage'
				},
				children: [{
						path: '/order/refundAfterSale/refundAfterSaleOrder',
						component: () => import('@/views/order/refundAfterSale/refundAfterSaleOrder'),
						name: 'refundAfterSaleOrder',
						meta: {
							title: 'refundAfterSaleOrder',
						}
					}, {
						path: '/order/refundAfterSale/index',
						component: () => import('@/views/order/refundAfterSale/index'),
						name: 'refundAfterSale',
						meta: {
							title: 'refundAfterSale',
						}
					}, {
						path: '/order/refundAfterSale/refundAfterReplace',
						component: () => import('@/views/order/refundAfterSale/refundAfterReplace'),
						name: 'refundAfterReplace',
						meta: {
							title: 'refundAfterReplace',
						}
					}, {
						path: '/order/refundAfterSale/refundAfterManage',
						component: () => import('@/views/order/refundAfterSale/refundAfterManage'),
						name: 'refundAfterOrder',
						hidden: true,
						meta: {
							title: 'refundAfterOrder',
						}
					},
					{
						path: '/order/refundAfterSale/exChangeRefundDetails',
						component: () => import('@/views/order/refundAfterSale/exChangeRefundDetails'),
						name: 'exChangeRefundDetails',
						hidden: true,
						meta: {
							title: 'exChangeRefundDetails',
						}
					},
					{
						path: '/order/refundAfterSale/refundCreateOrder',
						component: () => import('@/views/order/refundAfterSale/refundCreateOrder'),
						name: 'refundCreateOrder',
						hidden: true,
						meta: {
							title: 'refundCreateOrder',
						}
					},
					{
						path: '/order/refundAfterSale/zfbPaymentRecord',
						component: () => import('@/views/order/refundAfterSale/zfbPaymentRecord'),
						name: 'zfbPaymentRecord',
						meta: {
							title: 'zfbPaymentRecord',
						}
					},
				]
			},

			{
				path: '/order/supplyRefund/index',
				component: () => import('@/views/order/supplyRefund/index'),
				name: 'supplyRefund',
				hidden: true,
				meta: {
					title: 'supplyRefund',
				}
			},
			{
				path: '/order/supplyRefund/supplyRefundDetails',
				component: () => import('@/views/order/supplyRefund/supplyRefundDetails'),
				name: 'supplyRefundDetails',
				hidden: true,
				meta: {
					title: 'supplyRefundDetails',
				}
			},
			{
				path: '/order/supplierOrderBatch',
				component: () => import('@/views/order/supplierOrderBatch'),
				name: 'supplierOrderBatch',
				meta: {
					title: 'supplierOrderBatch',
				}
			},
			{
				path: '/order/orderDetailpage',
				component: () => import('@/views/order/orderMangepage/orderDetailpage'),
				name: 'orderDetailpage',
				hidden: true,
				meta: {
					title: 'orderDetailpage',
				}
			},
			{
				path: '/order/distributeOrder/distributeDetail',
				component: () => import('@/views/order/distributeOrder/distributeDetail'),
				name: 'distributeDetail',
				hidden: true,
				meta: {
					title: 'distributeDetail',
				}
			},
			{
				path: '/order/refundAfterSale/refundDetails',
				component: () => import('@/views/order/refundAfterSale/refundDetails'),
				name: 'refundDetails',
				hidden: true,
				meta: {
					title: 'refundDetails',
				}
			},
			{
				path: '/order/afterSalesReasons/afterSalesReasons',
				component: () => import('@/views/order/afterSalesReasons/afterSalesReasons'),
				name: 'afterSalesReasons',
				meta: {
					title: 'afterSalesReasons',
				}
			},
			{
				path: '/order/logisticsAssistant/bindingLogistics',
				component: () => import('@/views/order/logisticsAssistant/bindingLogistics'),
				name: 'bindingLogistics',
				meta: {
					title: 'bindingLogistics',
				}
			},
			{
				path: '/order/orderComment/orderComment',
				component: () => import('@/views/order/orderComment/orderComment'),
				name: 'orderComment',
				meta: {
					title: 'orderComment',
				}
			},
			{
				path: '/order/appealOrder/appealOrder',
				component: () => import('@/views/order/appealOrder/index'),
				name: 'appealOrder',
				meta: {
					title: 'appealOrder',
				}
			},
		]
	},
	//营销模块
	{
		path: '/market',
		component: Layout,
		meta: {
			title: 'market',
			icon: 'home'
		},
		children: [{
				path: '/market/coupon/index',
				component: () => import('@/views/treeMenu/threeMenupage'),
				name: 'coupon',
				meta: {
					title: 'coupon',
					isPage: true,
					url: '/market/coupon/index'
				},
				children: [{
						path: '/market/coupon/couponReceiveRecord',
						component: () => import('@/views/market/coupon/couponReceiveRecord'),
						name: 'couponReceiveRecord',
						meta: {
							title: 'couponReceiveRecord',
						}
					}, {
						path: '/market/coupon/couponGiveRecord',
						component: () => import('@/views/market/coupon/couponGiveRecord'),
						name: 'couponGiveRecord',
						meta: {
							title: 'couponGiveRecord',
						}
					},
					{
						path: '/market/coupon/index',
						component: () => import('@/views/market/coupon/index'),
						name: 'couponIndex',
						hidden: true,
						meta: {
							title: 'couponIndex',
						}
					},
				]
			},
			{
				path: '/market/discountActivity/index',
				component: () => import('@/views/market/discountActivity/index'),
				name: 'discountActivity',
				hidden: true,
				meta: {
					title: 'discountActivity',
				}
			},

			{
				path: '/market/limitedDiscount',
				component: () => import('@/views/market/limitedDiscount'),
				name: 'limitedDiscount',
				meta: {
					title: 'limitedDiscount',
				}
			},
			{
				path: '/market/frightTool',
				component: () => import('@/views/market/frightToolpage/frightTool'),
				name: 'frightTool',
				hidden: true,
				meta: {
					title: 'frightTool',
					// icon: 'goods'
				}
			},
			{
				path: '/market/packPrice/index',
				component: () => import('@/views/market/packPrice/index'),
				name: 'packPrice',
				hidden: true,
				meta: {
					title: 'packPrice',
				}
			},

			{
				path: '/market/orderCashBack/index',
				component: () => import('@/views/market/orderCashBack/index'),
				name: 'orderCashBack',
				hidden: true,
				meta: {
					title: 'orderCashBack',
				}
			},
			{
				path: '/market/coupon/editDiscountTicketDetail',
				component: () => import('@/views/market/coupon/editDiscountTicketDetail'),
				name: 'editDiscountTicketDetail',
				hidden: true,
				meta: {
					title: 'editDiscountTicketDetail',
				}
			},
			{
				path: '/market/discountActivity/editOverMoney',
				component: () => import('@/views/market/discountActivity/editOverMoney'),
				name: 'editOverMoney',
				hidden: true,
				meta: {
					title: 'editOverMoney',
				}
			},
			{
				path: '/market/addDiscount',
				component: () => import('@/views/market/addDiscount'),
				name: 'addDiscount',
				hidden: true,
				meta: {
					title: 'addDiscount',
					// icon: 'goods'
				}
			},
			{
				path: '/market/packPrice/editPriceDetaile',
				component: () => import('@/views/market/packPrice/editPriceDetaile'),
				name: 'editPriceDetaile',
				hidden: true,
				meta: {
					title: 'editPriceDetaile',
				}
			},
			{
				path: '/market/orderCashBack/editCashDetaile',
				component: () => import('@/views/market/orderCashBack/editCashDetaile'),
				name: 'editCashDetaile',
				hidden: true,
				meta: {
					title: 'editCashDetaile',
				}
			},
			{
				path: '/market/addFeighttool',
				component: () => import('@/views/market/frightToolpage/addFeighttool'),
				name: 'addFeighttool',
				hidden: true,
				meta: {
					title: 'addFeighttool',
					// icon: 'goods'
				}
			},
			{
				path: '/market/addFeighttool',
				component: () => import('@/views/market/frightToolpage/addFeighttool'),
				name: 'addFeighttool',
				hidden: true,
				meta: {
					title: 'addFeighttool',
					// icon: 'goods'
				}
			},
			{
				path: '/market/lookFeighttool',
				component: () => import('@/views/market/frightToolpage/lookFeighttool'),
				name: 'lookFeighttool',
				hidden: true,
				meta: {
					title: 'lookFeighttool',
					// icon: 'goods'
				}
			},
			{
				path: '/market/channel',
				component: () => import('@/views/market/channel/index'),
				name: 'channel',
				hidden: true,
				meta: {
					title: 'channel',
					// icon: 'goods'
				}
			},
			{
				path: '/market/channelStatistics',
				component: () => import('@/views/market/channel/statistics'),
				name: 'channelStatistics',
				hidden: true,
				meta: {
					title: 'channelStatistics',
					// icon: 'goods'
				}
			},
			{
				path: '/market/lottery',
				component: () => import('@/views/market/lottery/index'),
				name: 'lottery',
				hidden: true,
				meta: {
					title: 'lottery',
					// icon: 'goods'
				}
			},
			{
				path: '/market/lottery/editLotteryDetail',
				component: () => import('@/views/market/lottery/editLotteryDetail'),
				name: 'editLotteryDetail',
				hidden: true,
				meta: {
					title: 'editLotteryDetail',
					// icon: 'goods'
				}
			},
			{
				path: '/market/payGifts',
				component: () => import('@/views/market/payGifts/index'),
				name: 'payGifts',
				hidden: true,
				meta: {
					title: 'payGifts',
					// icon: 'goods'
				}
			},
			{
				path: '/market/payGifts/editPayGiftsDetail',
				component: () => import('@/views/market/payGifts/editPayGiftsDetail'),
				name: 'editPayGiftsDetail',
				hidden: true,
				meta: {
					title: 'editPayGiftsDetail',
					// icon: 'goods'
				}
			},
			{
				path: '/market/payGifts/lookGiftActive',
				component: () => import('@/views/market/payGifts/lookGiftActive'),
				name: 'lookGiftActive',
				hidden: true,
				meta: {
					title: 'lookGiftActive',
					// icon: 'goods'
				}
			},
			// 专题活动
			{
				path: '/market/themeActivity/index',
				component: () => import('@/views/market/themeActivity/index'),
				name: 'themeActivity',
				meta: {
					title: 'themeActivity',
				}
			},
			{
				path: '/market/themeActivity/themeActivityEdit',
				component: () => import('@/views/market/themeActivity/themeActivityEdit'),
				name: 'themeActivityEdit',
				hidden: true,
				meta: {
					title: 'themeActivityEdit',
				}
			},
			{
				path: '/market/secondKill/index',
				component: () => import('@/views/market/secondKill/index'),
				name: 'secondKill',
				meta: {
					title: 'secondKill',
				}
			},
			{
				path: '/market/secondKill/secondKillEdit',
				component: () => import('@/views/market/secondKill/secondKillEdit'),
				name: 'secondKillEdit',
				hidden: true,
				meta: {
					title: 'secondKillEdit',
				}
			},
			{
				path: '/market/secondKill/secondKillDetail',
				component: () => import('@/views/market/secondKill/secondKillDetail'),
				name: 'secondKillDetail',
				hidden: true,
				meta: {
					title: 'secondKillDetail',
				}
			},
			{
				path: '/market/orderCouponBack/index',
				component: () => import('@/views/market/orderCouponBack/index'),
				name: 'orderCouponBack',
				// hidden:true,
				meta: {
					title: 'orderCouponBack',
				}
			},
			{
				path: '/market/orderCouponBack/editCouponBackDetail',
				component: () => import('@/views/market/orderCouponBack/editCouponBackDetail'),
				name: 'editCouponBackDetail',
				hidden: true,
				meta: {
					title: 'editCouponBackDetail',
				}
			},
			{
				path: '/market/combinedGoods/index',
				component: () => import('@/views/market/combinedGoods/index'),
				name: 'combinedGoods',
				meta: {
					title: 'combinedGoods',
				}
			},
			{
				path: '/market/combinedGoods/editCombinedGoods',
				component: () => import('@/views/market/combinedGoods/editCombinedGoods'),
				name: 'editCombinedGoods',
				hidden: true,
				meta: {
					title: 'editCombinedGoods',
				}
			},
			{
				path: '/market/goodsReunion/index',
				component: () => import('@/views/market/goodsReunion/index'),
				name: 'goodsReunion',
				meta: {
					title: 'goodsReunion',
				}
			},
			{
				path: '/market/goodsBarter/index',
				component: () => import('@/views/market/goodsBarter/index'),
				name: 'goodsBarter',
				meta: {
					title: 'goodsBarter',
				}
			},
			{
				path: '/market/luckDraw/index',
				component: () => import('@/views/market/luckDraw/index'),
				name: 'luckDraw',
				meta: {
					title: 'luckDraw',
				}
			},
			{
				path: '/market/luckDraw/drawRecord',
				component: () => import('@/views/market/luckDraw/drawRecord'),
				name: 'drawRecord',
				hidden: true,
				meta: {
					title: 'drawRecord',
				}
			},
			{
				path: '/market/luckDraw/drawCreate',
				component: () => import('@/views/market/luckDraw/drawCreate'),
				name: 'drawCreate',
				hidden: true,
				meta: {
					title: 'drawCreate',
				}
			},
			{
				path: '/market/promotionActivities/index',
				component: () => import('@/views/market/promotionActivities/index'),
				name: 'promotionActivities',
				meta: {
					title: 'promotionActivities',
				}
			},
			{
				path: '/market/promotionActivities/promotiondataList',
				component: () => import('@/views/market/promotionActivities/promotiondataList'),
				name: 'promotiondataList',
				hidden: true,
				meta: {
					title: 'promotiondataList',
				}
			}, {
				path: '/market/promotionActivities/promotionAuthList',
				component: () => import('@/views/market/promotionActivities/promotionAuthList'),
				name: 'promotionAuthList',
				hidden: true,
				meta: {
					title: 'promotionAuthList',
				}
			}, {
				path: '/market/promotionActivities/promotionEdit',
				component: () => import('@/views/market/promotionActivities/promotionEdit'),
				name: 'promotionEdit',
				hidden: true,
				meta: {
					title: 'promotionEdit',
				}
			},
			{
			    path: '/market/spellGroup/index',
			    component: () => import('@/views/market/spellGroup/index'),
			    name: 'spellGroup',
			    meta: {
			         title: 'spellGroup',
			    }
			},
			{
			    path: '/market/spellGroup/groupCreate',
			    component: () => import('@/views/market/spellGroup/groupCreate'),
			    name: 'groupCreate',
				 hidden:true,
			    meta: {
			         title: 'groupCreate',
			    }
			},
			{
			    path: '/market/spellGroup/groupRecord',
			    component: () => import('@/views/market/spellGroup/groupRecord'),
			    name: 'groupRecord',
				 hidden:true,
			    meta: {
			         title: 'groupRecord',
			    }
			}
		]
	},

	//客户页面
	{
		path: '/Customer',
		component: Layout,
		meta: {
			title: 'Customer',
			// icon: 'goods'
		},
		children: [{
				path: '/Customer/CustomerSearch',
				component: () => import('@/views/Customer/CustomerSearch'),
				name: 'CustomerSearch',
				meta: {
					title: 'CustomerSearch',
					// icon: 'goods'
				},

			},
			{
				path: '/Customer/vipLevel',
				component: () => import('@/views/Customer/vipLevel'),
				name: 'vipLevel',
				hidden: true,
				meta: {
					title: 'vipLevel',
				}
			},
			{
				path: '/Customer/growthValue',
				component: () => import('@/views/Customer/growthValue'),
				name: 'growthValue',
				hidden: true,
				meta: {
					title: 'growthValue',
					// icon: 'goods'
				}
			}, {
				path: '/Customer/pointsManager',
				component: () => import('@/views/Customer/pointsManager'),
				name: 'pointsManager',
				hidden: true,
				meta: {
					title: 'pointsManager',
					// icon: 'goods'
				}
			}, {
				path: '/Customer/customerTag',
				component: () => import('@/views/Customer/customerTag'),
				name: 'customerTag',
				hidden: true,
				meta: {
					title: 'customerTag',
					// icon: 'goods'
				}
			}, {
				path: '/Customer/calendarCheckIn/index',
				component: () => import('@/views/Customer/calendarCheckIn/index'),
				name: 'calendarCheckIn',
				hidden: true,
				meta: {
					title: 'calendarCheckIn'
				}
			},
			{
				path: '/Customer/integralMall/index',
				component: () => import('@/views/Customer/integralMall/index'),
				name: 'integralMall',
				hidden: true,
				meta: {
					title: 'integralMall',
				}
			},
			{
				path: '/Customer/issueCoupon/index',
				component: () => import('@/views/Customer/issueCoupon/index'),
				name: 'issueCoupon',
				hidden: true,
				meta: {
					title: 'issueCoupon',
				}
			},
			{
				path: '/Customer/pointsRulesEdit',
				component: () => import('@/views/Customer/pointsRulesEdit'),
				name: 'pointsRulesEdit',
				hidden: true,
				meta: {
					title: 'pointsRulesEdit',
				}
			},
			{
				path: '/Customer/issueCoupon/editIssueCoupon',
				component: () => import('@/views/Customer/issueCoupon/editIssueCoupon'),
				name: 'editIssueCoupon',
				hidden: true,
				meta: {
					title: 'editIssueCoupon',
				}
			},
			{
				path: '/Customer/integralMall/editIntegralExchange',
				component: () => import('@/views/Customer/integralMall/editIntegralExchange'),
				name: 'editIntegralExchange',
				hidden: true,
				meta: {
					title: 'editIntegralExchange',
				}
			},
			{
				path: '/Customer/vipEdit',
				component: () => import('@/views/Customer/vipEdit'),
				name: 'vipEdit',
				hidden: true,
				meta: {
					title: 'vipEdit',
				}
			}, {
				path: '/Customer/CustomerDetail',
				component: () => import('@/views/Customer/CustomerDetail'),
				name: 'CustomerDetail',
				hidden: true,
				meta: {
					title: 'CustomerDetail',
				}
			},
			{
				path: '/Customer/vipList',
				component: () => import('@/views/Customer/vipList'),
				name: 'vipList',
				meta: {
					title: 'vipList',
				}
			},
			{
				path: '/Customer/vipDetail',
				component: () => import('@/views/Customer/vipDetail'),
				name: 'vipDetail',
				hidden: true,
				meta: {
					title: 'vipDetail',
				}
			},
			{
				path: '/Customer/vipCreateOrder',
				component: () => import('@/views/Customer/vipCreateOrder'),
				name: 'vipCreateOrder',
				hidden: true,
				meta: {
					title: 'vipCreateOrder',
				}
			},
			{
				path: '/Customer/vipBatchCoupon',
				component: () => import('@/views/Customer/vipBatchCoupon'),
				name: 'vipBatchCoupon',
				hidden: true,
				meta: {
					title: 'vipBatchCoupon',
				}
			},
		]
	},
	// 商城配置
	{
		path: '/mallPage',
		component: Layout,
		meta: {
			title: 'mallPage',
			icon: 'goods'
		},
		children: [{
				path: '/mallPage/mallPagemange',
				component: () => import('@/views/mallPage/mallPagemange'),
				name: 'mallPagemange',
				meta: {
					title: 'mallPagemange',
				}
			},
			{
				path: '/mallPage/mallPageDetails',
				component: () => import('@/views/mallPage/mallPageDetails'),
				name: 'mallPageDetails',
				hidden: true,
				meta: {
					title: 'mallPageDetails',
				}
			},
			{
				path: '/mallPage/mallPageThemeDetails',
				component: () => import('@/views/mallPage/mallPageThemeDetails'),
				name: 'mallPageThemeDetails',
				hidden: true,
				meta: {
					title: 'mallPageThemeDetails',
				}
			},
		],
	},
	//直播模块
	{
		path: '/broadCast',
		component: Layout,
		meta: {
			title: 'broadCast',
			icon: 'goods'
		},
		children: [

			{
				path: '/broadCast/index',
				component: () => import('@/views/broadcastManage/index'),
				name: 'broadcastManage',
				meta: {
					title: 'broadcastManage',
				}
			}

		]
	},
	//财务模块
	{
		path: '/finance',
		component: Layout,
		meta: {
			title: 'finance'
		},
		children: [{
				path: '/finance/collectionAccount/index',
				component: () => import('@/views/finance/collectionAccount/index'),
				name: 'collectionAccount',
				meta: {
					title: 'collectionAccount',
				}
			},
			{
				path: '/finance/collectionAccount/authentication',
				component: () => import('@/views/finance/collectionAccount/authentication'),
				name: 'authentication',
				hidden: true,
				meta: {
					title: 'authentication',
				}
			},
			{
				path: '/finance/record/index',
				component: () => import('@/views/finance/record/index'),
				name: 'record',
				meta: {
					title: 'record',
				}
			},
			{
				path: '/finance/record/detial',
				component: () => import('@/views/finance/record/detial'),
				name: 'recordDetial',
				hidden: true,
				meta: {
					title: 'recordDetial',
				}
			},
			{
				path: '/finance/exchangeRecord/exchangeRecord',
				component: () => import('@/views/finance/exchangeRecord/exchangeRecord'),
				name: 'exchangeRecord',
				meta: {
					title: 'exchangeRecord',
				}
			},
			{
				path: '/finance/reportForms',
				component: () => import('@/views/finance/reportForms/index'),
				name: 'reportForms',
				meta: {
					title: 'reportForms',
				}
			}
		]
	},
	//发圈素材
	{
		path: '/material',
		component: Layout,
		meta: {
			title: 'material'
		},
		children: [{
				path: '/material/materialList/materialList',
				component: () => import('@/views/material/materialList/materialList'),
				name: 'materialList',
				meta: {
					title: 'materialList',
				}
			},
			{
				path: '/material/addMaterial/addMaterial',
				component: () => import('@/views/material/addMaterial/addMaterial'),
				name: 'addMaterial',
				hidden: true,
				meta: {
					title: 'addMaterial',
				}
			},
		]
	},
	//下载中心
	{
		path: '/download',
		component: Layout,
		meta: {
			title: 'download',
		},
		children: [{
			path: '/download/index',
			component: () => import('@/views/download/index'),
			name: 'download',
			meta: {
				title: 'download',
			}
		},{
			path: '/versionMG/index',
			component: () => import('@/views/versionMG/index'),
			name: 'versionMG',
			meta: {
				title: 'versionMG',
			}
		} ]
	},
	//设置模块
	{
		path: '/setting',
		component: Layout,
		meta: {
			title: 'setting',
			icon: 'goods'
		},
		children: [{
				path: '/setting/navigation/index',
				component: () => import('@/views/setting/navigation/index'),
				name: 'navigation',
				meta: {
					title: 'navigation',
				}
			},
			{
				path: '/setting/adManage/index',
				component: () => import('@/views/setting/adManage/index'),
				name: 'adManage',
				meta: {
					title: 'adManage',
				}
			},
			{
				path: '/setting/adManage/adManageDetial',
				component: () => import('@/views/setting/adManage/adManageDetial'),
				name: 'adManageDetial',
				hidden: true,
				meta: {
					title: 'adManageDetial',
				}
			}, {
				path: '/setting/noticeManage/index',
				component: () => import('@/views/setting/noticeManage/index'),
				name: 'noticeManage',
				meta: {
					title: 'noticeManage',
				}
			}, {
				path: '/setting/afterSaleSetting',
				component: () => import('@/views/setting/afterSaleSetting'),
				name: 'afterSaleSetting',
				meta: {
					title: 'afterSaleSetting',
				}
			},
			{
				path: '/setting/businessAddress/index',
				component: () => import('@/views/setting/businessAddress/index'),
				name: 'businessAddress',
				meta: {
					title: 'businessAddress',
				}
			},
			{
				path: '/setting/businessAddress/editBusinessAddress',
				component: () => import('@/views/setting/businessAddress/editBusinessAddress'),
				name: 'editBusinessAddress',
				hidden: true,
				meta: {
					title: 'editBusinessAddress',
				}
			},
			{
				path: '/setting/shippingTemplates',
				component: () => import('@/views/setting/frightModelpage/shippingTemplates'),
				name: 'shippingTemplates',
				meta: {
					title: 'shippingTemplates',
				}
			},
			{
				path: '/setting/addTemplate',
				component: () => import('@/views/setting/frightModelpage/addTemplate'),
				name: 'addTemplate',
				hidden: true,
				meta: {
					title: 'addTemplate',
				}
			},
			{
				path: '/setting/mallSetting',
				component: () => import('@/views/setting/mallSetting'),
				name: 'mallSetting',
				meta: {
					title: 'mallSetting',
				}
			},
			{
				path: '/setting/otherSetting',
				component: () => import('@/views/setting/otherSetting'),
				name: 'otherSetting',
				meta: {
					title: 'otherSetting',
				}
			},
			{
				path: '/setting/sendSetting',
				component: () => import('@/views/setting/sendSetting'),
				name: 'sendSetting',
				meta: {
					title: 'sendSetting',
				}
			},
			{
				path: '/setting/tradeSetting',
				component: () => import('@/views/setting/tradeSetting'),
				name: 'tradeSetting',
				meta: {
					title: 'tradeSetting',
				}
			},
			{
				path: '/setting/paySetting',
				component: () => import('@/views/setting/paySetting'),
				name: 'paySetting',
				meta: {
					title: 'paySetting',
				}
			},
			{
				path: '/setting/index',
				component: () => import('@/views/setting/index'),
				name: 'editSetting',
				meta: {
					title: 'editSetting',
				}
			},
			{
				path: '/setting/invitationPoster',
				component: () => import('@/views/setting/invitationPoster'),
				name: 'invitationPoster',
				hidden: true,
				meta: {
					title: 'invitationPoster',
				}
			},
			{
				path: '/setting/roleManage/index',
				component: () => import('@/views/setting/roleManage/index'),
				name: 'roleManage',
				meta: {
					title: 'roleManage',
				}
			},
			{
				path: '/setting/jurMenu',
				component: () => import('@/views/setting/jurMenu'),
				name: 'jurMenu',
				meta: {
					title: 'jurMenu',
					// icon: 'drag'
				}
			},
			{
				path: '/setting/staffMange',
				component: () => import('@/views/setting/staffMange'),
				name: 'staffMange',
				meta: {
					title: 'staffMange',
				}
			},
			{
				path: '/setting/setMenupage',
				component: () => import('@/views/setting/setMenu/setMenupage'),
				name: 'setMenupage',
				hidden: true,
				meta: {
					title: 'setMenupage',
				}
			},
			{
				path: '/setting/exportsSetting',
				component: () => import('@/views/setting/exportsSetting'),
				name: 'exportsSetting',
				hidden: true,
				meta: {
					title: 'exportsSetting',
				}
			},
			{
				path: '/setting/roleManage/editRoleManage',
				component: () => import('@/views/setting/roleManage/editRoleManage'),
				name: 'editRoleManage',
				hidden: true,
				meta: {
					title: 'editRoleManage',
				}
			},
			{
				path: '/setting/operationLog',
				component: () => import('@/views/setting/operationLog'),
				name: 'operationLog',
				meta: {
					title: 'operationLog',
				}
			},
			{
				path: '/setting/cooperationCenter/cooperationCenter',
				component: () => import('@/views/setting/cooperationCenter/cooperationCenter'),
				name: 'cooperationCenter',
				meta: {
					title: 'cooperationCenter',
				}
			},
			{
				path: '/setting/agreement',
				component: () => import('@/views/setting/agreement'),
				name: 'agreement',
				meta: {
					title: 'agreement',
				}
			},
			{
				path: '/setting/rewardSet',
				component: () => import('@/views/setting/rewardSet/index'),
				name: 'rewardSet',
				meta: {
					title: 'rewardSet',
				}
			},
		]
	},
];
