import {
    login,
    logout,
    surePassword,
} from '@/api/login'
import {
    pcBaseInfo
} from '@/api/TurnTomySelf'
import staticImage from '@/assets/staticImages';
const user = {
    state: {
        displayName: '',
        sessionId: '',
        userId: '',
        userName: '',
        // user: '',
        // status: '',
        // code: '',
        Mallname: '',
        mallPhone: '',
        MallAvatar: '',
        UserId: '',
        IsOpenCommunity: false, //是否开启社群活动
        loginState: false,
        moduleList: [{
            title: '商品展示',
            list: [{
                    name: '搜索',
                    icon: staticImage.menu15,
                    moduleType: 16
                },
                {
                    name: '图片导航',
                    icon: staticImage.menu8,
                    moduleType: 9
                },
                {
                    name: '商品',
                    icon: staticImage.menu1,
                    moduleType: 2
                },
                {
                    name: '热区连接',
                    icon: staticImage.menu11,
                    moduleType: 12
                },
                {
                    name: '积分商城',
                    icon: staticImage.menu5,
                    moduleType: 6
                },
                {
                    name: '优惠卷',
                    icon: staticImage.menu17,
                    moduleType: 18
                },
            ]
        }],
        ShopName: '商城后台',
        ShopLogo: '',
        ShopmallName: '',
        ShopthirdName: '',
        ComponentId: '',
        // name: '',
        // avatar: '',
        // introduction: '',
        roles: '',
        // setting: {
        //   articlePlatform: []
        // }
    },



    mutations: {
        SET_LOGINSTATE: (state, loginState) => {
            state.loginState = loginState
        },
        SET_DISPLAYNAME: (state, displayName) => {
            state.displayName = displayName
        },
        SET_SESSIONId: (state, sessionId) => {
            state.sessionId = sessionId
        },
        SET_USERID: (state, userId) => {
            state.userId = userId
        },
        SET_USERNAME: (state, userName) => {
            state.userName = userName
        },
        SET_MALLNAME: (state, Mallname) => {
            state.Mallname = Mallname
        },
        SET_MALLPHONE: (state, mallPhone) => {

            state.mallPhone = mallPhone
        },
        SET_MALLAVATAR: (state, MallAvatar) => {
            state.MallAvatar = MallAvatar
        },

        SET_MALLUSERID: (state, UserId) => {
            state.UserId = UserId

        },

        // SET_CODE: (state, code) => {
        //   state.code = code
        // },
        SET_TOKEN: (state, token) => {
            state.token = token
        },

        SET_SHOPNAME: (state, ShopName) => {
            state.ShopName = ShopName
        },
        SET_SHOPLOGO: (state, ShopLogo) => {
            state.ShopLogo = ShopLogo
        },
        SET_SHOPMALLNAME: (state, ShopmallName) => {
            state.ShopmallName = ShopmallName
        },
        SET_THIRDNAME: (state, ShopthirdName) => {
            state.ShopthirdName = ShopthirdName
        },
        SET_COMPONENT: (state, ComponentId) => {
            state.ComponentId = ComponentId
        },
        SET_ISOPENCOMMUNITY: (state, bol) => {
            state.IsOpenCommunity = bol
        },
        SET_ROLES: (state, roles) => {
            state.roles = roles
        }
    },

    actions: {
        // 用户名登录
        Login({
            commit
        }, userInfo) {
            const username = userInfo.username.trim()
            return new Promise((resolve, reject) => {
                login(username, userInfo.password).then(response => {
                    const data = response
                    if (data.success) {
                        // 先加着看效果
                        commit('SET_ISGET', false)
                        commit('SET_LOGINSTATE', true)
                        window.localStorage.setItem('islogin', 'true');
                        // commit('SET_TOKEN', data.Result.SessionId)
                        // commit('SET_MALLNAME', data.Result.EmployeeName)
                        // commit('SET_MALLPHONE', data.Result.Phone)
                        // commit('SET_MALLAVATAR', data.Result.WxHeadUrl)
                        // commit('SET_COMPONENT', data.Result.ComponentId)
                        // window.localStorage.setItem('IsOpenWithdrawalsToAlipay',data.Result.IsOpenWithdrawalsToAlipay);
                        // window.localStorage.setItem('IsOpenWithdrawalsToBankCard', data.Result.IsOpenWithdrawalsToBankCard);
                        // window.localStorage.setItem('mlmzpageName', data.Result.EmployeeName)
                        // window.localStorage.setItem('mlmzpagePhone', data.Result.Phone)
                        // window.localStorage.setItem('mlmzpageWxurl', data.Result.WxHeadUrl)
                        // window.localStorage.setItem('mlmzpageComponentId', data.Result.ComponentId)
                        // resolve()
                    } else {
                        commit('SET_LOGINSTATE', false)
                    }
                    resolve()

                }).catch(error => {
                    reject(error)
                })
            })
        },
        //获取商城名称和第三方平台名称（商城信息）
        GetPcShopInfo({
            commit
        }) {
            // let info = {}
            // const domain = info
            return new Promise((resolve, reject) => {
                pcBaseInfo().then(response => {
                    const data = response

                    if (data.IsSuccess) {

                        // ThirdMallname
                        // 先注释着
                        commit('SET_SHOPMALLNAME', data.Result.MallName)
                        commit('SET_THIRDNAME', data.Result.ComponentName)
                        window.localStorage.setItem('shopnameTitle', data.Result.MallName)
                        window.localStorage.setItem('shopthirdName', data.Result.ComponentName)
                        // setToken(data.Result.SessionId)
                        resolve()
                    }
                }).catch(error => {
                    reject(error)
                })
            })
        },

        // 获取用户信息

        // GetUserInfo({
        // 	commit,
        // 	state
        // }) {
        // 	return new Promise((resolve, reject) => {
        // 		getUserInfo(state.token).then(response => {
        // 			const data = response.Result
        // 			console.log('data获取用户信息---', data)
        // 			// commit('SET_MALLNAME', data.MallName)
        // 			// commit('SET_MALLPHONE', data.Phone)
        // 			// commit('SET_MALLUSERID', data.MallId)
        // 			resolve(response)
        // 		}).catch(error => {
        // 			reject(error)
        // 		})
        // 	})
        // },

        // GetUserInfo({
        // 	commit,
        // 	state
        // }) {
        // 	return new Promise((resolve, reject) => {
        // 		getUserInfo(state.token).then(response => {
        // 			const data = response.Result
        // 			console.log('data获取用户信息',data)
        // 			// commit('SET_MALLNAME', data.MallName)
        // 			// commit('SET_MALLPHONE', data.Phone)
        // 			// commit('SET_MALLUSERID', data.MallId)
        // 			resolve(response)
        // 		}).catch(error => {
        // 			reject(error)
        // 		})
        // 	})
        // },


        //改变密码
        ChangePass({
            commit
        }, userpassInfo) {
            console.log(commit);
            const newps = userpassInfo.newPs
            const oldps = userpassInfo.oldPs
            return new Promise((resolve, reject) => {
                surePassword(oldps, newps).then(response => {
                    console.log(response);
                    if (response.success) {
                        commit('SET_MALLUSERID', '');
                    }
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },

        // 登出
        LogOut({
            commit
        }) {
            return new Promise((resolve, reject) => {
                logout().then(() => {
                    console.log('登出');
                    // 先注释着
                    commit('SET_ISGET', false)

                    commit('SET_TOKEN', '')

                    window.localStorage.setItem('islogin', 'false');
                    // resolve()
                    // this.$store.dispatch('delVisitedViews', view).then((views) => {
                    // 	if (this.isActive(view)) {
                    // 		const latestView = views.slice(-1)[0]
                    // 		if (latestView) {
                    // 			this.$router.push(latestView)
                    // 		} else {
                    // 			this.$router.push('/')
                    // 		}
                    // 	}
                    // })

                    // router.push({
                    // 	path: '/login'
                    // })
                    //先注释着
                    resolve()
                }).catch(error => {
                    reject(error)
                })
            })
        },

        // 前端 登出
        FedLogOut({
            commit
        }) {
            return new Promise(resolve => {

                commit('SET_TOKEN', '')

                window.localStorage.setItem('islogin', 'false');

                // 先注释的
                // router.push({
                // 	path: '/login'
                // })

                resolve()
            })
        },

        // 动态修改权限
        // ChangeRoles({ commit }, role) {
        //   return new Promise(resolve => {
        //     commit('SET_TOKEN', role)
        //     setToken(role)
        //     getUserInfo(role).then(response => {
        //       const data = response.data
        //       commit('SET_ROLES', data.roles)
        //       commit('SET_NAME', data.name)
        //       commit('SET_AVATAR', data.avatar)
        //       commit('SET_INTRODUCTION', data.introduction)
        //       resolve()
        //     })
        //   })
        // }
    }
}

export default user
