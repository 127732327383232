import Vue from 'vue'


//格式化正整数
Vue.prototype.formatParseInt = function (obj, key) {
    var tempNum = obj[key];
    tempNum = tempNum.replace(/[^\d]/g, ''); // 清除“数字”和“.”以外的字符
    if (tempNum.indexOf('.') < 0 && tempNum != '') {
        // 以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
        tempNum = parseInt(tempNum);
    }
    obj[key] = tempNum + '';
}

//跳转页面，同时清除之前的页面数据
// eslint-disable-next-line
Vue.prototype.publicToDetails = function (query,url,_this) {
    let params = '';
    let index = 0;
    console.log(query);
    for (let key in query) {
        params += (index==0?'?':'&')+key + '=' + query[key];
        index++;
    }
    let baseurl = process.env.NODE_ENV == 'production' ? '/static/index.html#' : '/#';
    window.open(baseurl+url+params,'_blank');
}

//跳转页面，同时清除之前的页面数据
// eslint-disable-next-line
Vue.prototype.publicToDetailsR = function (query,url,_this) {
    try {
        _this.$store.state.tagsView.visitedViews.map((item) => {
            if (item.path == url && item.query) {
                for (let key in query) {
                    item.query[key] = query[key];
                }
            }
        });
    } catch (error) {
        console.log(error);
    }

    _this.$router.push({
        path: url,
        query: query
    });
}
//格式化数字
/**
 *
 * @param {*} num  参数值
 * @param {*} type 类型。1:仅可输入正数，2:0～1的两位小数，3:保留两位小数,4仅可输入正整数
 * @param {*} _this
 */
Vue.prototype.publicFormatNum = function (num,type) {
    num = num + '';
    if (type == 1 || type == 2 || type == 3 || type == 8) {
        // eslint-disable-next-line
        num = num.replace(/[^\.\d]/g,'');
        //0～10的两位小数
        if (type == 8) {
            if(num === '0'){
                num = '0';
            }else if (num.match(/^(([0](\.\d{0,8}))|1)/g)) {
                num = num.match(/^(([0](\.\d{0,8}))|1)/g)[0];
            }else{
                num = '';
            }
        }
        //0～1的两位小数
        if (type == 2) {
            if(num === '0'){
                num = '0';
            }else if (num.match(/^(([0](\.\d{0,2}))|1)/g)) {
                num = num.match(/^(([0](\.\d{0,2}))|1)/g)[0];
            }else{
                num = '';
            }
        }
        //保留两位小数
        if (type == 3) {
            num = num.match(/^\d*(\.?\d{0,2})/g)[0];
        }
    }else if (type == 4) {
        //正整数
        num = num.replace(/\D/g,'');
        console.log(num);
        if (num || num === 0 || num === '0') {
            num = parseInt(num);
        }else{
            num = '';
        }
    }
    return num;
}
