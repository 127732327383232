<template>
	<div v-if="!item.hidden&&item.children" class="menu-wrapper">
		<el-menu
			:index="item.name||item.path"
			background-color="#304156"
			text-color="#fff"
			active-text-color="#409EFF"
			:default-active="sildebarMenu"
			:unique-opened="true"
			style="padding-bottom: 100px;"
		>
			<div v-for="child in item.children" :key="child.path">
				<template v-if="!child.hidden">
					<!-- 没有二级菜单 -->
					<div v-if="!child.children">
						<router-link :to="resolvePath(child.path)" :key="child.name">
							<el-menu-item :index="resolvePath(child.path)" style="padding-left: 40px;">
								<svg-icon v-if="child.meta&&child.meta.icon" :icon-class="child.meta.icon" />
								<span v-if="child.meta&&child.meta.title" slot="title">{{ generateTitle(child.meta.title) }}</span>
							</el-menu-item>
						</router-link>
					</div>
					<!-- 有二级菜单 -->
					<div v-if="child.children&&child.children.length">
						<el-submenu :index="resolvePath(child.path)">
							<template slot="title">
								<span style="padding-left: 20px;">{{generateTitle(child.meta.title)}}</span>
							</template>

							<div v-for="(record,indesx) in child.children" :key="indesx">
								<template v-if="!record.hidden">
									<!-- 有三级菜单 -->
									<div v-if="record.children&&record.children.length">
										<el-submenu :index="resolvePath(record.path)">
											<template slot="title">
												<span style="padding-left: 20px;" @click="toMenuPage(record)">{{generateTitle(record.meta.title)}}</span>
											</template>

											<div v-for="(recordc,indesx) in record.children" :key="indesx">
												<template v-if="!recordc.hidden">
													<router-link :to="resolvePath(recordc.path)" :key="recordc.name">
														<el-menu-item
															style="padding-left: 75px;"
															:index="resolvePath(recordc.path)"
														>{{generateTitle(recordc.meta.title)}}</el-menu-item>
													</router-link>
												</template>
											</div>
										</el-submenu>
									</div>
									<div v-else>
										<router-link :to="resolvePath(record.path)" :key="record.name">
											<el-menu-item
												style="padding-left: 57px;"
												:index="resolvePath(record.path)"
											>{{generateTitle(record.meta.title)}}</el-menu-item>
										</router-link>
									</div>
								</template>
							</div>
							<!-- <el-menu-item
								@click="goCustmer"
								index="1"
								style="padding-left: 57px;"
								v-if="child.path=='/customerServe'"
							>进入客服系统</el-menu-item>-->
						</el-submenu>
					</div>
				</template>
			</div>
		</el-menu>
	</div>
</template>

<script>
import { mapGetters } from "vuex";

import path from "path";
import { generateTitle } from "@/utils/i18n";
import Hamburger from "@/components/Hamburger";

import { XJChatGetLoginCert } from "../../../../api/login.js";

export default {
	name: "SidebarItem",
	props: {
		// route object
		item: {
			type: Object,
			required: true,
		},
		isNest: {
			type: Boolean,
			default: false,
		},
		basePath: {
			type: String,
			default: "",
		},
		slectRouter: {
			type: String,
			default: "",
		},
	},
	data () {
		return {
			onlyOneChild: null,
			sildebarMenu: "0",
			openedData: [],
		};
	},

	created () {
		this.sildebarMenu = this.$route.path;
		// console.log(this.item, '侧边栏数据')
	},
	watch: {
		slectRouter (value) {
			this.sildebarMenu = value;
			// console.log(this.item, "侧边栏数据");
			// this.item.children.map(record => {
			// 	// console.log(record, 999)
			// 	if (record.children && record.children.length) {
			// 		this.openedData.push(record.path)
			// 	}
			// })
		},
	},
	components: {
		Hamburger,
	},
	computed: {
		...mapGetters(["sidebar"]),
	},
	methods: {
		// 进入客服系统
		async goCustmer () {
			this.sildebarMenu = this.resolvePath(this.item.children[0].path);

			const res = await XJChatGetLoginCert();

			// window.open('http://kftest.xiaojiankeji.com/page/#/service?cert='+res.LoginCert)
			if (process.env.NODE_ENV == "production") {
				window.open(
					"https://kf.xiaojiankeji.com/page/#/home/clientlist?fromMely=1&cert=" +
					res.LoginCert +
					"&host=" +
					location.host
				);
			} else {
				window.open(
					"http://localhost:8008/#/home/clientlist?fromMely=1&cert=" +
					res.LoginCert +
					"&host=" +
					location.host
				);
			}
		},

		toggleSideBar () {
			this.$store.dispatch("toggleSideBar");
		},
		hasOneShowingChild (children) {
			const showingChildren = children.filter((item) => {
				if (item.hidden) {
					return false;
				} else {
					this.onlyOneChild = item;
					return true;
				}
			});
			if (showingChildren.length === 1) {
				return true;
			}
			return false;
		},
		resolvePath (...paths) {
			return path.resolve(this.basePath, ...paths);
        },
        //页面跳转
        toMenuPage(record){
            console.log(record);
            if (record.meta && record.meta.isPage) {
                this.$router.push({
                    path: record.meta.url
                });
            }
        },
		generateTitle,
	},
};
</script>
