let baseurl = 'https://yzadmin.api.yizyic.com/';
export default {
    // http://blspcapi.edianma.com
    // http://lastonepcapitest.xiaojiankeji.com
    // http://lastonepcapitest.xiaojiankeji.com http://blspcapi.edianma.com

    BASE_URL: baseurl,
    NEW_BASE_URL: process.env.NODE_ENV == 'production' ? '/' : 'http://172.16.4.151:8050',

    HOST: process.env.NODE_ENV == 'production' ? '/' : '',

    IMG_BASE: '',

    EXPORT_URL: '',
    GO_URL: process.env.NODE_ENV == 'production' ? '' : '/#',

    UPLOAD_IMG: baseurl + 'admin/oss/batchUpload?businessType=1&uploadType=1',
    UPLOAD_VIDEO: baseurl + 'admin/oss/batchUpload?businessType=2&uploadType=1',
    UPLOAD_FILE: baseurl + 'admin/oss/batchUpload?businessType=4&uploadType=1',
    UPLOAD_ZIP: baseurl + 'admin/oss/batchUpload?businessType=4&uploadType=1',
    DEFAULT_HEADER: 'https://api.static.huibogoulive.cn/images/melyshop/pcDefaultHeader.png',
}
