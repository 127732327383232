import config from '@/config/enum.js';
import orderConfig from '@/assets/js/order-config.js';

function pluralize(time, label) {
    if (time === 1) {
        return time + label
    }
    return time + label + 's'
}

export function timeAgo(time) {
    const between = Date.now() / 1000 - Number(time)
    if (between < 3600) {
        return pluralize(~~(between / 60), ' minute')
    } else if (between < 86400) {
        return pluralize(~~(between / 3600), ' hour')
    } else {
        return pluralize(~~(between / 86400), ' day')
    }
}

export function parseTime(time, cFormat) {
    if (arguments.length === 0) {
        return null
    }

    if ((time + '').length === 10) {
        time = +time * 1000
    }

    const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
    let date
    if (typeof time === 'object') {
        date = time
    } else {
        date = new Date(parseInt(time))
    }
    const formatObj = {
        y: date.getFullYear(),
        m: date.getMonth() + 1,
        d: date.getDate(),
        h: date.getHours(),
        i: date.getMinutes(),
        s: date.getSeconds(),
        a: date.getDay()
    }
    const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
        let value = formatObj[key]
        if (key === 'a') return ['一', '二', '三', '四', '五', '六', '日'][value - 1]
        if (result.length > 0 && value < 10) {
            value = '0' + value
        }
        return value || 0
    })
    return time_str
}

export function formatTime(time, option) {
    time = +time * 1000
    const d = new Date(time)
    const now = Date.now()

    const diff = (now - d) / 1000

    if (diff < 30) {
        return '刚刚'
    } else if (diff < 3600) { // less 1 hour
        return Math.ceil(diff / 60) + '分钟前'
    } else if (diff < 3600 * 24) {
        return Math.ceil(diff / 3600) + '小时前'
    } else if (diff < 3600 * 24 * 2) {
        return '1天前'
    }
    if (option) {
        return parseTime(time, option)
    } else {
        return d.getMonth() + 1 + '月' + d.getDate() + '日' + d.getHours() + '时' + d.getMinutes() + '分'
    }
}

/* 数字 格式化*/
export function nFormatter(num, digits) {
    const si = [{
            value: 1E18,
            symbol: 'E'
        },
        {
            value: 1E15,
            symbol: 'P'
        },
        {
            value: 1E12,
            symbol: 'T'
        },
        {
            value: 1E9,
            symbol: 'G'
        },
        {
            value: 1E6,
            symbol: 'M'
        },
        {
            value: 1E3,
            symbol: 'k'
        }
    ]
    for (let i = 0; i < si.length; i++) {
        if (num >= si[i].value) {
            return (num / si[i].value + 0.1).toFixed(digits).replace(/\.0+$|(\.[0-9]*[1-9])0+$/, '$1') + si[i].symbol
        }
    }
    return num.toString()
}

export function html2Text(val) {
    const div = document.createElement('div')
    div.innerHTML = val
    return div.textContent || div.innerText
}

export function toThousandslsFilter(num) {
    return (+num || 0).toString().replace(/^-?\d+/g, m => m.replace(/(?=(?!\b)(\d{3})+$)/g, ','))
}

// 验证是否输入正确的手机号
export function surePhone(numbers) {
    let reg = /^1[3|4|5|7|8][0-9]\d{8}$/;
    return reg.text(numbers)
}


// 推手等级
export function getDistributRoleName(type) {
    let output = '管理员';
    if(type <= 4) {
        config.Level_list.map(item =>{
            if (item.pusherLevel == type) {
                output = item.pusherLabel;
            }
        });
    } else {
        output = '注册用户';
    }
    return output
}
// 会员等级
export function getUserLevelName(type) {
    let output = '注册用户';
    if(type <= 4) {
        config.Level_list.map(item =>{
            if (item.pusherLevel == type) {
                output = item.pusherLabel;
            }
        });
    }
    return output
}

// 点数来源：0:系统赠送、1:系统扣除、2:粉丝订单、3:粉丝售后、4:自己订单、5:自己售后
export function getSourceTypeName(type) {
    let output = '未知';
    switch (type) {
        case 5:
            output = '自己售后';
            break;
        case 4:
            output = '自己订单';
            break;
        case 3:
            output = '粉丝售后';
            break;
        case 2:
            output = '粉丝订单';
            break;
        case 1:
            output = '系统扣除';
            break;
        case 0:
            output = '系统赠送';
            break;

    }
    return output
}

// 用户信息
export function userInfoSplit(str) {
    if(str) {
        let list = str.split(',');
        let lineStr = "ID:" + list[0];
        let levelName = '注册用户';
        if(list[3] <= 4) {
            levelName = config.Level_list[list[3]-1] ? config.Level_list[list[3]-1].pusherLabel : '管理员';
        }
        lineStr += "\n" + list[1] + "(" +levelName+ ")";
        lineStr += "\n" + list[2];
        return lineStr;
    }
    return str
}

// 订单类型
export function orderTypeFilter(str) {
    str = str + '';
    const orderType = orderConfig.orderType;
    return orderType[str] ? orderType[str] : orderType[1];
}