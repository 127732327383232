import {
    MenuInit

} from '@/api/goods'
import {
    asyncRouterMap,
    constantRouterMap
} from '@/router'

import store from '@/store/index'

/**
 * 扁平化数组
 * @param  {[type]} array [description]
 * @param  {Number} depth [description]
 * @return {[type]}       [description]
 */
function flattenDepth(array, depth = 3) {
    let list = []
    let result = []
    array.forEach(item => {
        let d = depth
        list.push(item)
        if (item.subMenus && Array.isArray(item.subMenus) && d > 0) {
            list.push(...(flattenDepth(item.subMenus, --d)))
        }
    })
    result = list.map(item => {
        if (item.menuUrl) {
            return item.menuUrl
        } else {
            return item
        }
    })

    return result
}

/**
 * 递归过滤异步路由表，返回符合用户角色权限的路由表
 * @param asyncRouterMap
 * @param roles
 */
function filterAsyncRouter(asyncRouterMap, permissionList) {

    const accessedRouters = asyncRouterMap.filter(route => {
        if (route.name && permissionList.indexOf(route.name) != -1 || route.hidden) {
            return true
        }
        if (!route.name && route.children && route.children.length) {
            route.children = filterAsyncRouter(route.children, permissionList)
            if (route.children.length > 0) {
                return true
            }
        }
        return false
    })
    return accessedRouters


}


const permission = {
    state: {
        routers: constantRouterMap,
        addRouters: [],
        isGet: false,
        routerList: [],
    },
    mutations: {
        SET_ROUTERS: (state, routers) => {
            state.addRouters = routers
            state.routers = constantRouterMap.concat(routers)

        },
        SET_ISGET: (state, flag) => {
            state.isGet = flag
        },
        SET_ROUTERLIST: (state, routers) => {
            state.routerList = routers
        },
    },
    actions: {
        // 生成路由信息
        GenerateRoutes({
            commit
        }) {
            return new Promise((resolve, reject) => {
                MenuInit().then(response => {
                    const data = response
                    console.log(data, '初始化菜单数据')
                    if (!data.success) {
                        store.dispatch('FedLogOut').then(() => {
                            location.reload() // 为了重新实例化vue-router对象 避免bug
                        })
                    }
                    let list = flattenDepth(response.data.menuTree);
                    commit('SET_SHOPMALLNAME', response.data.backUser.userName);
                    commit('SET_ROLES', response.data.backUser.roles);

                    localStorage.setItem("mlmzpageRealName", response.data.backUser.realName);
                    localStorage.setItem("mlmzpageName", response.data.backUser.userName);
                    localStorage.setItem("mlmzpageUserId", response.data.backUser.userId);
                    localStorage.setItem("mlmzpageRoles", response.data.backUser.roles);

                    let accessedRouters = null;
                    commit('SET_ROUTERLIST', list)
                    accessedRouters = filterAsyncRouter(asyncRouterMap, list)

                    // console.log(accessedRouters,'是这一步出问题了吗')
                    let newaccessedRouters = []
                    accessedRouters.map(item => {
                        if (item) {
                            let flages1 = false;
                            let counts = 0
                            item.children.map(record => {
                                if (record.hidden) {
                                    counts++
                                }
                            })
                            flages1 = counts != item.children.length
                            if (flages1) {
                                newaccessedRouters.push(item)
                            }

                        }
                    })

                    // console.log(newaccessedRouters,'菜单初始化数据')
                    commit('SET_ROUTERS', newaccessedRouters)
                    commit('SET_ISGET', true)
                    resolve()
                }).catch(error => {
                    reject(error)
                })
            })
        },
    }
}

export default permission