<template>
	<div id="app">
		<router-view />
	</div>
</template>
<script>
	// 入口组件
	export default {
		name: 'App',
		created() {
			// 在页面加载时读取sessionStorage
			if (sessionStorage.getItem('store')) {
				this.$store.replaceState(Object.assign({}, this.$store.state, JSON.parse(sessionStorage.getItem('store'))))				
				console.log(this.$store,9999)
			}
			// 在页面刷新时将store保存到sessionStorage里
			window.addEventListener('beforeunload', () => {
				sessionStorage.setItem('store', JSON.stringify(this.$store.state))
			})
		},
		// mounted() {
		//   console.log('当前用户：', this.$store.state)
		// }
	}
</script>

<style>
	.tip-text { color: #ff4d51; font-size: 14px}
	.table-back-write.el-table th{
		background-color: #f8f8f9 !important;
	}
	.table-back-write.el-table td{
		background-color: #FFFFFF !important;
	}

	.el-table .success-row {
		background: #c5e1ff;
	}
	.scale-enter-active {
		animation: bounce-in .5s;
		transform-origin: 0 0;
	}

	.scale-leave-active {
		animation: bounce-in .5s reverse;
		transform-origin: 0 0;
	}

	.scaleRight-enter-active {
		animation: bounce-in .5s;
		transform-origin: 100% 0;
	}

	.scaleRight-leave-active {
		animation: bounce-in .5s reverse;
		transform-origin: 100% 0;
	}

	@keyframes bounce-in {
		0% {
			transform: scale(0);
		}

		100% {
			transform: scale(1);
		}
	}

	.bounce-out-left-enter-active {
		animation: bounce-out-left .2s;
	}

	.bounce-out-left-leave-active {
		animation: bounce-out-left .2s reverse;
	}

	@keyframes bounce-out-left {
		0% {
			transform: translateX(-30%);
		}

		100% {
			transform: translateX(0);
		}
	}

	::-webkit-scrollbar {
		width: 6px;
		height: 6px;
	}

	::-webkit-scrollbar-track {
		background-color: none;
	}

	::-webkit-scrollbar-thumb {
		background-color: #d4d5d6;
	}

	::-webkit-scrollbar-thumb:hover {
		background-color: #a4a5a6;
	}

	::-webkit-scrollbar-thumb:active {
		background-color: none;
	}

	.main-content {
		background: #fff;
		padding: 15px;
	}
	.el-image-viewer__btn>i.el-icon-circle-close::before {
		color: #fff;
	}

	.card-box.el-card>.el-card__header { background: #f3f3f3; padding: 10px;}
</style>
