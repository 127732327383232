
import staticImage from '@/assets/staticImages';

const user = {
	state: {
		imghost: 'http://img.xsmore.com/melyshop',
		uploadUrl: 'http://file.xsmore.com/api/image/upload?PlatForm=melyshop&Path=ShopDecorate',

		//装修模块列表
		moduleList: [
			// {
			// 	name: '公告',
			// 	icon: staticImage.menu19,
			// 	moduleType: 21
			// },
			// {
			// 	name: '搜索',
			// 	icon: staticImage.menu15,
			// 	moduleType: 16
			// },
			{
			    name: '标题栏',
			    icon: staticImage.menu14,
			    moduleType: 15
			},
			// {
			// 	name: '图片导航',
			// 	icon: staticImage.menu8,
			// 	moduleType: 9
			// },
			{
				name: '商品',
				icon: staticImage.menu1,
				moduleType: 2
			},
			{
				name: '热区链接',
				icon: staticImage.menu11,
				moduleType: 12
			},
			// {
			// 	name: '积分商城',
			// 	icon: staticImage.menu22,
			// 	moduleType: 20
			// },
			{
				name: '优惠券',
				icon: staticImage.menu17,
				moduleType: 18
			},
			// {
			// 	name: '直播',
			// 	icon: staticImage.menu20,
			// 	moduleType: 22
			// },
			// {
			// 	name: '猜你喜欢',
			// 	icon: staticImage.menu5,
			// 	moduleType: 6
			// },
			{
			    name: '秒杀',
			    icon: staticImage.menu16,
			    moduleType: 17
			},
			// {
			//     name: '商城新品',
			//     icon: staticImage.menu21,
			//     moduleType: 23
			// },
			// {
			//     name: '社群专享品',
			//     icon: staticImage.menu23,
			//     moduleType: 24
			// },
			// {
			//     name: '社群绑定',
			//     icon: staticImage.menu24,
			//     moduleType: 25
			// },
			{
			    name: '轮播图',
			    icon: staticImage.menu24,
			    moduleType: 26
			},
			{
			    name: '富文本',
			    icon: staticImage.menu14,
			    moduleType: 27
			},
		],

		//所有静态图片地址
		staticImage: staticImage,

		//功能页列表
		functionalPageList: [{
				id: 1,
                name: '必应鸟卡券',
                pageUrl:'/pagesA/cps/cpsGoodsList'
			},{
				id: 2,
                name: '复团商品',
                pageUrl:'/pagesA/goodsReunionList/goodsReunionList'
			},{
                id: 3,
                name: '升级礼包B',
                pageUrl:'/pagesA/upgradeGift/upgradeGift?giftType=1&upgradeType=3'
			},{
                id: 4,
                name: '升级礼包C',
                pageUrl:'/pagesA/upgradeGift/upgradeGift?giftType=1&upgradeType=4'
			},
            {
                id: 5,
                name: '抽奖-无限制',
                pageUrl:'/pagesA/drawLuck/drawLuck'
			},{
                id: 6,
                name: '抽奖-邀请新用户',
                pageUrl:'/pagesA/drawLuck/drawLuck?at=2'
			},{
                id: 7,
                name: '抽奖-下单+邀请新用户',
                pageUrl:'/pagesA/drawLuck/drawLuck?at=3'
			},
		]
	},
	mutations: {
		
	}
};

export default user;
