<template>
	<el-menu class="navbar" mode="horizontal">
		<hamburger :toggle-click="toggleSideBar" :is-active="sidebar.opened" class="hamburger-container" />
		
		<breadcrumb class="breadcrumb-container" />

		<div class="right-menu">
			<div class="addBtn">
				<span>{{MallName}}</span>
				<span>{{mallPhone}}</span>
				<span @click="changePasswaord">修改密码</span>
			</div>
			<el-dropdown class="avatar-container right-menu-item" trigger="click" :title="'欢迎用户'+name">
				<div class="avatar-wrapper">
					<img src="https://api.static.huibogoulive.cn/images/CJYP/static/img/icons/avatardefault.png" class="user-avatar">
					<!-- <i class="el-icon-caret-bottom" /> -->
				</div>
				<el-dropdown-menu slot="dropdown">
					<el-dropdown-item>
						<span style="display:block;" @click="logout">{{ $t('navbar.logOut') }}</span>
					</el-dropdown-item>

				</el-dropdown-menu>
			</el-dropdown>
		</div>

		<el-dialog title="修改密码" :visible.sync="SendDialogVisible" width="850px" :loading="loading">
			<el-form :model="sendForm" ref="sendForm" :rules="rules" style="width:47%;margin-left: 10%;">
				<el-form-item label="旧密码" label-width="150px" prop="oldPs">
					<el-input v-model="sendForm.oldPs" placeholder="请输入旧密码" :type="psoldType" class="inputBox" auto-complete="new-password" />
					<span class="show-pwd" @click="showoldPwd">
						<svg-icon icon-class="eye" />
					</span>
				</el-form-item>
				<el-form-item label="新密码" label-width="150px" prop="newPs">
					<el-input v-model="sendForm.newPs" placeholder="请输入新密码" :type="psnewType" class="inputBox" auto-complete="new-password" />
					<span class="show-pwd" @click="shownewPwd">
						<svg-icon icon-class="eye" />
						<!-- <svg-icon icon-class="cc-eye" /> -->
					</span>
				</el-form-item>
				<el-form-item label="确认密码" label-width="150px" prop="surePs">
					<el-input v-model="sendForm.surePs" placeholder="请输入确认密码" :type="psureType" class="inputBox" auto-complete="new-password" />
					<span class="show-pwd" @click="showsurePwd">
						<svg-icon icon-class="eye" />
					</span>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="SendDialogVisible=false">取 消</el-button>
				<el-button type="primary" @click="SubmitSend">确认</el-button>
			</span>
		</el-dialog>
	</el-menu>
</template>

<script>
	// import config from '@/config/index'
	import {
		mapGetters
	} from 'vuex'
	import Breadcrumb from '@/components/Breadcrumb'
	import Hamburger from '@/components/Hamburger'
	import ErrorLog from '@/components/ErrorLog'
	import Screenfull from '@/components/Screenfull'
	import LangSelect from '@/components/LangSelect'
	import ThemePicker from '@/components/ThemePicker'

	export default {
		components: {
			Breadcrumb,
			Hamburger,
			ErrorLog,
			Screenfull,
			LangSelect,
			ThemePicker
		},
		data() {
			return {
				loading: false,
				SendDialogVisible: false,
				psoldType: 'password',
				psnewType: 'password',
				psureType: 'password',
				rules: {
					oldPs: [{
						required: true,
						message: '请输入旧密码',
						trigger: 'blur'
					}, ],
					newPs: [{
						required: true,
						message: '请输入新密码',
						trigger: 'blur'
					}],
					surePs: [{
						required: true,
						message: '请输入确认密码',
						trigger: 'blur'
					}],
				},
				sendForm: {
					oldPs: '',
					newPs: '',
					surePs: '',
				},
				employInfo: {

				}
			}
		},
		computed: {
			...mapGetters([
				'sidebar',
				'name',
				'avatar',
				'MallName',
				'mallPhone',

			])
		},
	
		methods: {
			
			
			toggleSideBar() {
				this.$store.dispatch('toggleSideBar')
			},
			logout() {
			
				this.$store.dispatch('LogOut').then(() => {
					location.reload() // In order to re-instantiate the vue-router object to avoid bugs
				})
			},
			getInfo() {
				this.$store.dispatch('GetUserInfo')
			},
			//修改密码
			changePasswaord() {

				this.SendDialogVisible = true;
				this.sendForm.oldPs = '';
				this.sendForm.newPs = '';
				this.sendForm.surePs = '';
			},
			//确认
			SubmitSend() {
				this.$refs.sendForm.validate(valid => {
					if (valid) {

						if (this.sendForm.newPs != this.sendForm.surePs) {
							this.$message({showClose: true,
								message: '密码不一致',
								type: 'error'
							});
							return false
						}
						this.loading = true
						console.log(this.sendForm, '输入的数据')
						this.$store.dispatch('ChangePass', this.sendForm).then(() => {
							this.loading = false
							this.logout()
						}).catch(() => {
							this.loading = false
						})
					} else {
						console.log('error submit!!')
						return false
					}
				})
			},
			//显示旧密码
			showoldPwd() {
				if (this.psoldType === 'password') {
					this.psoldType = ''
				} else {
					this.psoldType = 'password'
				}
			},
			shownewPwd() {
				if (this.psnewType === 'password') {
					this.psnewType = ''
				} else {
					this.psnewType = 'password'
				}
			},
			showsurePwd() {
				if (this.psureType === 'password') {
					this.psureType = ''
				} else {
					this.psureType = 'password'
				}
			}

		},
		beforeMount() {
			this.getInfo()
		}

	}
</script>

<style lang="less" scoped>
	.navbar {
		height: 50px;
		line-height: 50px;
		border-radius: 0px !important;

		.show-pwd {
			position: absolute;
			right: 20px;
		}

		.hamburger-container {
			line-height: 58px;
			height: 50px;
			float: left;
			padding: 0 10px;
		}

		.breadcrumb-container {
			float: left;
		}

		.errLog-container {
			display: inline-block;
			vertical-align: top;
		}

		.right-menu {
			float: right;
			height: 100%;
			display: -webkit-box;
			display: -moz-box;
			display: -ms-flexbox;
			display: -webkit-flex;
			display: flex;

			.addBtn {
				span {
					cursor: pointer;
					font-size: 14px;
					margin-right: 30px;
				}
			}

			&:focus {
				outline: none;
			}

			.right-menu-item {
				display: inline-block;
				margin: 0 8px;
			}

			.screenfull {
				height: 20px;
			}

			.international {
				vertical-align: top;
			}

			.theme-switch {
				vertical-align: 15px;
			}

			.avatar-container {
				height: 50px;
				margin-right: 50px;

				.avatar-wrapper {
					cursor: pointer;
					margin-top: 10px;
					position: relative;

					.user-avatar {
						width: 30px;
						height: 30px;
						border-radius: 100%;
					}

					.el-icon-caret-bottom {
						position: absolute;
						right: -20px;
						top: 10px;
						font-size: 12px;
					}
				}
			}
		}
	}
</style>
