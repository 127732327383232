import request from '@/utils/request'

// 登录
export const login = (userName, password) => request('/admin/system/login', {
    userName,
    password
});
// 登出
export const logout = () => request('/admin/system/logout');
// 获取用户信息
export const getUserInfo = () => request('/pc/appstart/employeeinfo', {});

export const surePassword = (oldPsw, newPsw) => request('/admin/system/updatePsw', {
    oldPsw,
    newPsw
});

//进入客服系统获取登录参数 
export const XJChatGetLoginCert = (params) => request('/xj-chat/get-login-cert', params, 'post');