import Vue from 'vue'

import 'normalize.css/normalize.css' // A modern alternative to CSS resets

import Element from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

import '@/assets/css/index.less' // global css

import App from './App'
import router from './router/index'
import store from './store/index'

import i18n from './lang' // Internationalization
import './icons' // icon
import './errorLog' // error log
import '@/utils/common'
// 先注释着
import './permission' // permission control 

// import VueDraggable from 'vue-draggable'
// Vue.use(VueDraggable)

// import './mock' // simulation data

import VueQuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

import iView from 'iview';

// import 'iview/dist/styles/iview.css'; // 导入样式
// import Vue from 'vue'
// import BaiduMap from 'vue-baidu-map'

// Vue.use(BaiduMap, {
//   ak: 'xZk6fKMM1tjcxXas4AsegXk9NNQYqkOM'    //这个地方是官方提供的ak密钥
// })
import 'swiper/dist/css/swiper.css';
import VueClipboard from 'vue-clipboard2'

import configEnum from '@/config/enum.js'
Vue.prototype.LEVELLIST = configEnum.Level_list;
Vue.prototype.PRIZETYPE = configEnum.prizeType;

Vue.use(VueClipboard);
Vue.use(VueQuillEditor);
Vue.use(iView);

import * as filters from './filters' // global filters

Vue.use(Element, {
    size: 'medium', // set element-ui default size
    i18n: (key, value) => i18n.t(key, value)
});

// register global utility filters.
Object.keys(filters).forEach(key => {
    Vue.filter(key, filters[key])
});

import ButtonPermissions from '@/components/buttonPermissions';
Vue.component('buttonPermissions',ButtonPermissions);

Vue.config.productionTip = false;

new Vue({
    el: '#app',
    // BaiduMap,
    router,
    store,
    i18n,
    render: h => h(App)
}).$mount('#app');